import React, {useCallback, useContext, useEffect, useState} from "react";
import {DateRangePicker, SelectPicker, TagPicker} from "rsuite";
import moment from 'moment';

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";

import {AccessStatus} from "../../types/ApprovalForm";

import DataTable from "../DataTable";
import API from "../../helpers/API";

type ApprovalFormProps = {
	projectID: string|undefined;
	form: any
}

const ApprovalForm = ({projectID, form}: ApprovalFormProps) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main,resetToken} = useContext(MainContext);

	const [zones, setZones] = useState([]);

	const [workers, setWorkers] = useState([]);

	const [workerData, setWorkerData] = useState<any>({});

	const [table, setTable] = useState({
		fetched: false,
		schema: [
			{key: 'internalCardId', name: 'Worker ID', width: 200},
			{key: 'chineseName', name: 'Chinese Name', width: 200},
			{key: 'englishName', name: 'English Name', width: 200},
			{key: 'contractor', name: 'Contractor', width: 200},
		]
	})

	const fetchZones = useCallback(async () =>
	{
		const params = new URLSearchParams();

		params.append('projectID', projectID as string);
		params.append('page', '1');
		params.append('size', '1000000');

		API.call({
			url: `${main.apiUrl}/zones?${params.toString()}`,
			options: {
				method: 'get',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
			},
			successCallback: (res) =>
			{
				setZones(res.data.records.map((zone: any) => ({label: zone.name, value: zone._id})));
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}, []);

	const fetchWorkers = useCallback(async () =>
	{
		API.call({
			url: `${main.apiUrl}/attend/workers`,
			options: {
				method: 'post',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({projectID: projectID})
			},
			successCallback: (res) =>
			{
				setWorkers(res.data.map((worker: any) => ({label: `${worker.chineseName} ${worker.englishName}`, value: worker.internalCardId})));

				let workerList: any = {}

				for (const worker of res.data)
					workerList[worker.internalCardId] = worker;

				setWorkerData(workerList);
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}, [])

	const changeWorkers = useCallback((values: string[]) =>
	{
		let workerList = [];

		for (const workerID of values)
			workerList.push(workerData[workerID])

		form.setFieldValue('workers', workerList);

	}, [form.values.workers, workerData])

	useEffect(() =>
	{
		fetchZones();
		fetchWorkers();
	}, [])

	return <>
		<form onSubmit={form.handleSubmit}>
			<div className="row py-4">
				<div className="col-12">
					<div className="form-group">
						<label className="mb-3">Zone</label>
						<SelectPicker
						className={'select w-100'}
						menuClassName={`selectMenu`}
						data={zones}
						searchable={false}
						cleanable={false}
						appearance="subtle"
						value={form.values.zoneID}
						onChange={(value) =>
						{
							form.setFieldValue('zoneID', value);
						}}
						/>
						{((form.touched.zoneID && form.errors.zoneID)) &&
							<div className="invalid-feedback"
							     style={{display: 'block'}}>{JSON.stringify(form.errors)}</div>}
					</div>
				</div>

				{<div className="col-12">
					<div className="form-group">
						<label className="mb-3">Valid Through</label>
						<div>
							<DateRangePicker format="yyyy-MM-dd HH:mm"
							                 placeholder={'Dates'}
							                 placement={`bottomStart`} cleanable={false}
							                 defaultValue={[moment(form.values.startDateTime).toDate(), moment(form.values.endDateTime).toDate()]}
							                 disabledDate={(date) =>
							                 {
								                 return moment(date).isBefore(moment(moment().format('YYYY-MM-DD')))
							                 }}
							                 ranges={[]}
							                 onChange={(dates) =>
							                 {
								                 if (dates !== null)
								                 {
									                 form.setFieldValue('startDateTime', moment(dates[0]).toDate())
									                 form.setFieldValue('endDateTime', moment(dates[1]).toDate())
								                 }
							                 }}
							/>
						</div>
					</div>
				</div>}

				<div className="col-12">
					<div className="form-group">
						<label>Access</label>
						<div>
							<div className="btn-group btn-group-toggle" data-toggle="buttons">
								<button type="button" className={`btn ${(form.values.access === AccessStatus.ALLOW) ? 'btn-success' : 'btn-dark text-muted'}`}
								        onClick={() => form.setFieldValue('access', AccessStatus.ALLOW)}>
									ALLOW
								</button>
								{/*<button type="button" className={`btn ${(form.values.access === AccessStatus.DENY) ? 'btn-danger' : 'btn-dark text-muted'}`}
								        onClick={() => form.setFieldValue('access', AccessStatus.DENY)}>
									DENY
								</button>*/}
							</div>
						</div>
					</div>
				</div>

				<div className="col-12">
					<div className="form-group">
						<label className="mb-3">Workers</label>
						<TagPicker
						className={'tag w-100'}
						menuClassName={`selectMenu`}
						data={workers}
						searchable={true}
						cleanable={false}
						appearance="subtle"
						value={form.values.workers.map((worker: any) => worker.internalCardId)}
						onChange={(values) =>
						{
							changeWorkers(values);
						}}
						/>
						{((form.touched.workers && form.errors.workers)) &&
							<div className="invalid-feedback"
							     style={{display: 'block'}}>{form.errors.workers}</div>}
					</div>
				</div>
				<div className="col-12">
					<DataTable
					height={300}
					fetched={table.fetched}
					dataSource={`LOCAL`}
					data={form.values.workers}
					schema={table.schema}
					pagination={false}
					sortable={false}
					searchable={false}
					viewable={false}
					insertable={false}
					editable={false}
					removable={false}
					/*dataUrl={`${main.apiUrl}/zones`}*/
					onViewButtonClick={(rowData) =>
					{
					}}
					onDataFetch={() =>
					{
					}}
					onFetched={() =>
					{
						setTable({...table, fetched: true})
					}}
					onRemoveButtonClick={(rowData) =>
					{
					}}
					/>
				</div>
			</div>
			<div className="text-right">
				<button type="submit" className="btn button btn-theme">Submit</button>
			</div>
		</form>
	</>
}

export default ApprovalForm;