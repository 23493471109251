import React, {useCallback} from 'react';

const Sidebar = (props: any) =>
{
    const closeSideBar = () =>
    {
        document.getElementById('mainMenu')?.classList.toggle("sidebar--active");
        document.getElementById('mainmenu-backdrop')?.classList.toggle("active");
    };

    return (
    <>
        <aside id="mainMenu" className="sidebar navigation">
            <div className="scrollbar">
                <ul className="navigation__menu">
                    {props.children}
                </ul>
            </div>
        </aside>
        <div id='mainmenu-backdrop' className="mainmenu-backdrop" onClick={closeSideBar}/>
    </>
    );
};

export default Sidebar;