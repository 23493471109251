import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";

import Modal from '../../components/Modal';
import DataTable from "../../components/DataTable";

import {UserInfo} from "../../types/UserForm";
import API from "../../helpers/API";

const defaultFormValues: UserInfo = {
	id: '',
	username: '',
	projects: [],
}

const UserListPage = (props: any) =>
{
	const {alert, setAlert, loading, setLoading} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);

	const {id} = useParams();

	const navigate = useNavigate();

	const [table, setTable] = useState({
		fetched: false
	})

	const [removeModal, setRemoveModal] = useState({
		show: false,
		close: false,
		loading: true,
		data: {id: ''}
	});

	const removeUser = async (id: string) =>
	{
		API.call({
			url: `${main.apiUrl}/admin-users`,
			options: {
				method: 'delete',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({id: id})
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}

	useEffect(() =>
	{
		if(main.validated)
		{
			if(!main.isAdmin)
				navigate('/')
		}
	}, [])

	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Users<small>List of Users That Are Authorized to Access Admin Panel</small></h1>
				<button className="btn button btn-theme" onClick={e =>
				{
					navigate('/users/create')
				}}>
					Create User
				</button>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<DataTable
									fetched={table.fetched}
									dataSource={`REMOTE`}
									pagination={true}
									sortable={true}
									searchable={true}
									viewable={false}
									insertable={false}
									editable={false}
									removable={true}
									dataUrl={`${main.apiUrl}/admin-users`}
									onViewButtonClick={async (rowData) =>
									{
									}}
									onEditButtonClick={async (rowData) =>
									{
									}}
									onDataFetch={() =>
									{
									}}
									onFetched={() =>
									{
										setTable({...table, fetched: true})
									}}
									onRemoveButtonClick={(rowData) =>
									{
										console.log(rowData);
										setRemoveModal({...removeModal, show: true, data: {id: rowData._id}})
									}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<Modal
		show={removeModal.show}
		close={removeModal.close}
		title={'Confirm Remove User'}
		scrollable={true}
		size={"md"}
		centered={false}
		confirmButtons={true}
		buttonName={'Confirm Remove'}
		buttonClass={'btn-danger'}
		closeAction={() =>
		{
			setRemoveModal({...removeModal, show: false})
		}}
		confirmedAction={() =>
		{
			removeUser(removeModal.data.id);
		}}
		>
			Confirm Remove User?<br/>THIS CANNOT BE UNDONE!!!
		</Modal>
	</>
	);
}

export default UserListPage;