import React, {Component, useContext} from 'react';

import {MainContext} from "../contexts/MainContext";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import MenuItem from "../components/MenuItem";


const PageLayout = (props: any) =>
{
    const {main} = useContext(MainContext);

	const toggleSubMenu = (e: any) =>
	{
		e.preventDefault();
		e.stopPropagation();
		e.target.parentNode.classList.toggle("active");
	}

	return <>
		<Header sidebar={props.sidebar}/>

		<div className="main">
			{(props.sidebar) &&
			<>
				<Sidebar>
					{/*<li>
                        <MenuItem activeColor={"blue"} icon={"home"} label={"Authorized Pages"} location={"/"}/>
                    </li>*/}

					<li>
						<MenuItem activeColor={"blue"} icon={"grid"} label={"Projects"} location={"/"} end={true}/>
					</li>

					<li>
						<MenuItem activeColor={"yellow"} icon={"add-item-alt"} label={"Fields"} location={"/fields"}/>
					</li>

					{
					(main.isAdmin) &&
					<li>
						<MenuItem activeColor={"red"} icon={"users"} label={"Users"} location={"/users"}/>
					</li>
					}

					{/*<li>
                        <MenuItem activeColor={"red"} icon={"note"} label={"News"} location={"/news"}/>
                    </li>

                    <li className="navigation__sub">
                        <a href="" data-mae-action="submenu-toggle"
                           onClick={toggleSubMenu}><i
                        className="zwicon-paper-prototype green"/>Website Content</a>
                        <ul style={{listStyleType: 'none'}}>

                            <li>
                                <MenuItem activeColor={"white"} icon={"web"}
                                          label={"General"}
                                          location={"/contents/general"}/>
                            </li>
                            <li>
                                <MenuItem activeColor={"white"} icon={"calendar-month"}
                                          label={"Pages"}
                                          location={"/contents/pages"}/>
                            </li>
                        </ul>
                    </li>*/}
				</Sidebar>
			</>
			}
			{props.component}
		</div>
		<footer className="footer" style={{padding:'2rem 1rem'}}>
			Admin Panel SIT v2.0.0
		</footer>
	</>
}

export default PageLayout;