import { Button, Input, Toggle } from "rsuite";
import { useFormik } from "formik";
import { useEffect } from "react";

export interface ProjectConfigFormObject {
    configInput: string;
    configInputBoolean: boolean;
}

interface ProjectConfigEditPanelProps {
    label: string;
    defaultValue: string | number | boolean;
    onSubmitCallback: (value: string | boolean) => void;
    customValidation?: (
        values: ProjectConfigFormObject,
        errors: Record<string, string>
    ) => Record<string, string>;
}

const ProjectConfigEditPanel = ({
    label,
    defaultValue,
    onSubmitCallback,
    customValidation
}: ProjectConfigEditPanelProps) => {
    const formik = useFormik<ProjectConfigFormObject>({
        initialValues: {
            configInput: "",
            configInputBoolean: false
        },
        validate: (values) => {
            let errors: Record<string, string> = {};
            if (typeof defaultValue !== "boolean" && !values.configInput) {
                errors.configInput = "Required";
            } else if (
                typeof defaultValue === "number" &&
                !/^[+-]?\d+(\.\d+)?$/.test(values.configInput)
            ) {
                errors.configInput = "Invalid input";
            }

            if (customValidation) {
                errors = customValidation(values, errors);
            }
            return errors;
        },
        onSubmit: (values) =>
            onSubmitCallback(
                typeof defaultValue === "boolean" ? values.configInputBoolean : values.configInput
            )
    });

    useEffect(() => {
        if (defaultValue !== undefined) {
            if (["string", "number"].includes(typeof defaultValue)) {
                formik.setFieldValue("configInput", defaultValue.toString());
            }

            if (typeof defaultValue === "boolean") {
                formik.setFieldValue("configInputBoolean", defaultValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    return (
        <div>
            <div style={{ marginBottom: "15px" }}>{label}</div>

            <form onSubmit={formik.handleSubmit}>
                {typeof defaultValue === "boolean" && (
                    <Toggle
                        className="project-config-toggle"
                        checked={formik.values.configInputBoolean}
                        onChange={(value) => formik.setFieldValue("configInputBoolean", value)}
                    />
                )}

                {typeof defaultValue !== "boolean" && (
                    <Input
                        type={typeof defaultValue === "number" ? "number" : "text"}
                        onChange={(value) => formik.setFieldValue("configInput", value)}
                        value={formik.values.configInput}
                    />
                )}

                {formik.errors.configInput && formik.touched.configInput && (
                    <div style={{ color: "#ff0000", marginTop: "10px" }}>
                        {formik.errors.configInput}
                    </div>
                )}

                <div style={{ textAlign: "right", marginTop: "20px" }}>
                    <Button type="submit">Submit</Button>
                </div>
            </form>
        </div>
    );
};
export default ProjectConfigEditPanel;
