import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";

import DataTable from "../../components/DataTable";
import API from "../../helpers/API";

import {UserInfo} from "../../types/UserForm";


const defaultFormValues: UserInfo = {
	id: '',
	username: '',
	projects: [],
}

const UserCreatePage = (props: any) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);

	const navigate=useNavigate();

	const form = useFormik({
		initialValues: defaultFormValues,

		validationSchema: Yup.object({
			username: Yup.string().required("Please Enter Username").min(1, "Zone ID Too Short"),
			projects: Yup.array().min(1, 'Please Add At Least ONE Project')
		}),

		enableReinitialize: true,

		validateOnChange: false,

		onSubmit: (values) =>
		{
			createUser(values);
		},
	})

	const createUser = (values: any) =>
	{
		let formData = JSON.parse(JSON.stringify(values));

		API.call({
			url: `${main.apiUrl}/admin-users`,
			options: {
				method: 'post',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(formData)
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: 'success',
					message: res.message.content
				})

				navigate('/users')
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}

	const [table, setTable] = useState({
		fetched: false,
		schema: [
			{key: 'internalCardId', name: 'Worker ID', width: 200},
			{key: 'chineseName', name: 'Chinese Name', width: 200},
			{key: 'englishName', name: 'English Name', width: 200},
			{key: 'contractor', name: 'Contractor', width: 200},
		]
	})

	useEffect(() =>
	{
		if(main.validated)
		{
			if(!main.isAdmin)
				navigate('/')
		}
	}, [])

	const addProjectToList = (data: any) =>
	{
		const projects = JSON.parse(JSON.stringify(form.values.projects));
		const exists = projects.filter((proj: any) => (proj.id === data.projectUID));

		if (exists.length === 0)
		{
			projects.push({
				id: data.projectUID,
				projectID: data.projectId,
				name: ((data.titleChi.length === 0) ? data.titleEng : data.titleChi),
			});
		}

		form.setFieldValue('projects', projects)
	}

	const removeProjectFromList = (index: number) =>
	{
		const projects = JSON.parse(JSON.stringify(form.values.projects));

		projects.splice(index, 1);

		form.setFieldValue('projects', projects)
	}

	useEffect(() =>
	{
	}, [])

	return <>
		<section className={`content user ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Users<small>List of Users That Are Authorized to Access Admin Panel</small></h1>
			</header>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<form onSubmit={form.handleSubmit}>
								<div className="row py-3">
									<div className="col-12">
										<div className="form-group">
											<label className="mb-3">Username</label>
											<input type="text" className="form-control" name="username"
											       placeholder="Name"
											       onChange={form.handleChange}
											       value={form.values.username}
											       autoComplete="off"/>
											{((form.touched.username && form.errors.username)) &&
											<div className="invalid-feedback"
											     style={{display: 'block'}}>{form.errors.username}</div>}

										</div>

										<div className="form-group">
											<label className="mb-3">Projects</label>
											{
											(form.values.projects.length === 0) &&
											<>
												<div className="fonts font-20 weight-700">
													No Projects
												</div>
												{((form.touched.projects && form.errors.projects)) &&
												<div className="invalid-feedback"
												     style={{display: 'block'}}>{JSON.stringify(form.errors.projects)}</div>}
											</>
											}
											<div className="row">
												{
												(form.values.projects.length > 0) && (form.values.projects.map((project, index) =>
												<div className="col-md-4 col-sm-12 mb-3">
													<div className="d-flex align-items-center justify-content-between project px-3">
														<div className="name">{project.name}</div>
														<div className="close text-light" onClick={() =>
														{
															removeProjectFromList(index)
														}}>
															<i className="zwicon-close"/>
														</div>
													</div>
												</div>
												))

												}
											</div>
										</div>
									</div>


									<div className="col-12">
										<DataTable
										fetched={table.fetched}
										dataSource={`REMOTE`}
										pagination={true}
										sortable={true}
										searchable={true}
										viewable={false}
										insertable={true}
										editable={false}
										removable={false}
										dataUrl={`${main.apiUrl}/projects/fetch`}
										onDataFetch={() =>
										{
										}}
										onFetched={() =>
										{
											setTable({...table, fetched: true})
										}}
										onInsertButtonClick={(rowData) =>
										{
											console.log(rowData);
											addProjectToList(rowData);
										}}
										/>
									</div>

									{/*<div className="col-12">
					<div className="form-group">
						<label className="mb-3">Valid Through</label>
						<div>
							<DateRangePicker format="yyyy-MM-dd HH:mm"
							                 placeholder={'Dates'}
							                 placement={`bottomStart`} cleanable={false}
							                 defaultValue={[moment(form.values.startDateTime).toDate(), moment(form.values.endDateTime).toDate()]}
							                 disabledDate={(date) =>
							                 {
								                 return moment(date).isBefore(moment(moment().format('YYYY-MM-DD')))
							                 }}
							                 ranges={[]}
							                 onChange={(dates) =>
							                 {
								                 if (dates !== null)
								                 {
									                 form.setFieldValue('startDateTime', moment(dates[0]).toDate())
									                 form.setFieldValue('endDateTime', moment(dates[1]).toDate())
								                 }
							                 }}
							/>
						</div>
					</div>
				</div>*/}

								</div>
								<div className="text-right">
									<button type="submit" className="btn button btn-theme">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
}

export default UserCreatePage;