import React, {useContext} from 'react';
import {Navigate} from "react-router-dom";
import {useCookies} from "react-cookie";

import {MainContext} from "../contexts/MainContext";

export default function Admin(props:any)
{
    const {main} = useContext(MainContext);
    const [cookies] = useCookies(['token'])

    return (
    <>
        {
            (function ()
            {
                if (main.validated)
                {
                    if (main.expired || cookies.token === null)
                        return <Navigate to={'/login'}/>

                    return props.children;
                }
            })()
        }
    </>
    );
}