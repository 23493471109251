import React, {Component} from 'react';
import { useParams } from 'react-router-dom';

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import MenuItem from "../components/MenuItem";

const ProjectLayout = (props: any) =>
{
	const {projectID,zoneID}=useParams();

	const toggleSubMenu = (e: any) =>
	{
		e.preventDefault();
		e.stopPropagation();
		e.target.parentNode.classList.toggle("active");
	}

	return <>
		<Header sidebar={props.sidebar}/>

		<div className="main">
			{(props.sidebar) &&
				<>
					<Sidebar>
						<li>
							<MenuItem activeColor={"white"} icon={"arrow-left"} label={"Back To Zone List"}
							          location={`/${projectID}/zones`} end={true}/>
						</li>

						<li>
							<MenuItem activeColor={"blue"} icon={"grid"} label={"Worker List"} location={`/${projectID}/${zoneID}/workers`}/>
						</li>

						<li>
							<MenuItem activeColor={"yellow"} icon={"grid"} label={"Zoner List"} location={`/${projectID}/${zoneID}/zoners`}/>
						</li>

						<li>
							<MenuItem activeColor={"red"} icon={"grid"} label={"SMS Phone List"} location={`/${projectID}/${zoneID}/phones`}/>
						</li>

						{/*<li>
                        <MenuItem activeColor={"red"} icon={"note"} label={"News"} location={"/news"}/>
                    </li>

                    <li className="navigation__sub">
                        <a href="" data-mae-action="submenu-toggle"
                           onClick={toggleSubMenu}><i
                        className="zwicon-paper-prototype green"/>Website Content</a>
                        <ul style={{listStyleType: 'none'}}>

                            <li>
                                <MenuItem activeColor={"white"} icon={"web"}
                                          label={"General"}
                                          location={"/contents/general"}/>
                            </li>
                            <li>
                                <MenuItem activeColor={"white"} icon={"calendar-month"}
                                          label={"Pages"}
                                          location={"/contents/pages"}/>
                            </li>
                        </ul>
                    </li>*/}
					</Sidebar>
				</>
			}
			{props.component}
		</div>
		<footer className="footer" style={{padding:'2rem 1rem'}}>
			Admin Panel SIT v2.0.0
		</footer>
	</>
}

export default ProjectLayout;