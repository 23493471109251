import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import {useFormik} from "formik";
import {SelectPicker, Toggle} from "rsuite";
import SimpleBar from 'simplebar-react';
import * as Yup from "yup";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import DataTable from "../components/DataTable";
import QuickPanel from "../components/QuickPanel";
import Modal from "../components/Modal";
import API from "../helpers/API";

type FieldProps = {
	sidebar: string;
}

type Option = {
	value: string,
	default: boolean;
}

export enum InputType
{
	NUMBER = 'NUMBER',
	USER_SELECT = 'USER_SELECT',
	USER_INPUT = 'USER_INPUT'
}

type FormFields = {
	name: string,
	type: InputType,
	options: Option[],
	defaultValue: string,
};

const defaultFormValues: FormFields = {
	name: '',
	type: InputType.NUMBER,
	options: [],
	defaultValue: '',
}

const FieldPage = (props: FieldProps) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);
	const [cookies] = useCookies(['token'])

	const [table, setTable] = useState({
		fetched: false
	})

	const [quickPanel, setQuickPanel] = useState({
		show: false,
		processing: false,
		close: false,
		title: 'CREATE NEW FIELD',
		buttonTitle: 'Create',
	});

	const [modal, setModal] = useState({
		show: false,
		close: false,
		loading: true,
		data: {id: ''}
	});

	const createField = useCallback(async (values: any) =>
	{
		setQuickPanel({
			...quickPanel,
			processing: true,
		})

		API.call({
			url: `${main.apiUrl}/fields`,
			options: {
				method: 'post',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(values)
			},
			successCallback: (res) =>
			{
				form.resetForm();

				setQuickPanel({
					...quickPanel,
					processing: false,
					show: false,
					close: true,
				})

				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				form.resetForm();

				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});

	}, [quickPanel]);

	const updateField = useCallback(async (values: any) =>
	{
		setQuickPanel({
			...quickPanel,
			processing: true,
		})

		API.call({
			url: `${main.apiUrl}/fields`,
			options: {
				method: 'put',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(values)
			},
			successCallback: (res) =>
			{
				form.resetForm();

				setQuickPanel({
					...quickPanel,
					processing: false,
					show: false,
					close: true,
				})

				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setQuickPanel({
					...quickPanel,
					processing: false,
				})

				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});

	}, [quickPanel]);

	const editField = (rowData: any) =>
	{
		form.setValues(rowData);

		setQuickPanel({
			...quickPanel,
			show: true,
			title: 'Edit Field',
			buttonTitle: 'Update',
		})
	}

	const removeField = async (id: string) =>
	{
		API.call({
			url: `${main.apiUrl}/fields`,
			options: {
				method: 'delete',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({id: id})
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}

	const form = useFormik({
		initialValues: defaultFormValues,

		validationSchema: Yup.object({
			name: Yup.string().required("Please Enter Field Name").min(1, "Field Name Too Short"),
			type: Yup.string().required("Please Enter Type."),
			options: Yup.array().nullable().when('type', {
				is: (type: string) => (type === 'USER_SELECT'),
				then: Yup.array().min(1, 'Attribute Must Consist of at least ONE Property').of(Yup.object().shape({
					value: Yup.string().required("Please Enter Option Name").min(1, "Option Name Too Short"),
					default: Yup.boolean(),
				})).test({
					name: 'one-true',
					message: 'Please Select One Option As Default Value.',
					test: (val: any) => (val.filter((value: any) => value.default === true).length > 0),
				})
			}),
			defaultValue: Yup.string().when('type', {
				is: (type: string) => (type !== 'USER_SELECT'),
				then: Yup.string().required("Please Enter Default Value For The Field").min(1, "Default Value Too Short"),
			}),
		}),

		enableReinitialize: true,

		validateOnChange: false,

		onSubmit: (values) =>
		{
			if ('_id' in values)
				updateField(values);
			else
				createField(values);
		},
	})

	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Fields<small>List Of Fields For Building Zoner CSV.</small></h1>
				<button className="btn button btn-theme" onClick={e =>
				{
					setQuickPanel({
						...quickPanel, show: true,
						title: 'CREATE NEW FIELD',
						buttonTitle: 'Create',
					})
				}}>
					Add Field
				</button>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<DataTable
									fetched={table.fetched}
									dataSource={`REMOTE`}
									pagination={true}
									sortable={true}
									searchable={true}
									viewable={false}
									insertable={false}
									editable={true}
									removable={true}
									dataUrl={`${main.apiUrl}/fields`}
									onDataFetch={() =>
									{
									}}
									onFetched={() =>
									{
										setTable({...table, fetched: true})
									}}
									onEditButtonClick={editField}
									onRemoveButtonClick={(rowData) =>
									{
										setModal({...modal, show: true, data: {id: rowData._id}})
									}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<QuickPanel
		title={quickPanel.title}
		subtitle={''}
		show={quickPanel.show}
		processing={quickPanel.processing}
		close={quickPanel.close}
		closeAction={() =>
		{
			form.resetForm();
			setQuickPanel({...quickPanel, show: false})
		}}
		>
			<SimpleBar style={{maxHeight: '80vh'}}>
				<div className="px-5 py-4">
					<form onSubmit={form.handleSubmit} style={{maxHeight: '75vh'}}>
						<div className="row">
							<div className="col-12 mb-4">
								<div className="fonts weight-500 font-16 mb-2">Field Name</div>
								<div className="form-input">
									<input type="text" className="form-control" name="name"
									       placeholder="Name"
									       onChange={form.handleChange}
									       value={form.values.name}
									       autoComplete="off"/>

									{
									((form.touched.name && form.errors.name)) &&
										<div className="invalid-feedback" style={{display: 'block'}}>
											{form.errors.name}
										</div>
									}
								</div>
							</div>

							<div className="col-12 mb-4">
								<div className="fonts weight-500 font-16 mb-2">Input Type</div>
								<div className="form-input">
									<SelectPicker
									className={'select w-100'}
									menuClassName={`selectMenu`}
									data={[
										{label: 'NUMBER', value: 'NUMBER'},
										{label: 'USER INPUT STRING', value: 'USER_INPUT'},
										{label: 'USER SELECT', value: 'USER_SELECT'},
									]}
									searchable={false}
									cleanable={false}
									appearance="subtle"
									value={form.values.type}
									onChange={(value) =>
									{
										form.setFieldValue('type', value);
										form.setFieldValue('options', null)

										if (value === 'USER_SELECT')
											form.setFieldValue('options', [{value: '', default: false}])
									}}
									/>

									{
									((form.touched.type && form.errors.type)) &&
										<div className="invalid-feedback" style={{display: 'block'}}>
											{form.errors.type}
										</div>
									}
								</div>
							</div>

							{
							(form.values.type !== 'USER_SELECT') &&
								<div className="col-12 mb-4">
									<div className="fonts weight-500 font-16 mb-2">Default Value</div>
									<div className="form-input">
										<input type="text" className="form-control" name="defaultValue"
										       placeholder="Default Value"
										       onChange={form.handleChange}
										       value={form.values.defaultValue}
										       autoComplete="off"/>

										{
										((form.touched.defaultValue && form.errors.defaultValue)) &&
											<div className="invalid-feedback" style={{display: 'block'}}>
												{form.errors.defaultValue}
											</div>
										}
									</div>
								</div>
							}

							{
							(form.values.type === 'USER_SELECT') &&
								<div className="col-12 mt-4 mb-5">
									<div className="row mb-4 justify-content-between">
										<div className="col-6">
											<div className="fonts weight-500 font-16 mb-2">Options</div>
										</div>
										<div className="col-2">
											<div className="fonts weight-500 font-16 mb-2">Default</div>
										</div>

										<div className="col-4 text-right">
											<button type="button" className="button btn-sm btn-theme py-2 px-3"
											        onClick={(e) =>
											        {
												        const options = JSON.parse(JSON.stringify(form.values.options));
												        options.push({value: '', default: false});
												        form.setFieldValue('options', options)
											        }}
											>
												Add Option
											</button>
										</div>
									</div>

									{
										((form.values.options.length > 0) && form.values.options.map((option: Option, index: number) =>
										<div className="row mb-3 pt-2 align-items-center">
											<div className="col-6">
												<div className="form-input">
													<input type="text" className="form-control" name={`options[${index}].value`}
													       placeholder="Default Value"
													       onChange={(e) =>
													       {
														       const target = e.target as HTMLInputElement;
														       const options: any = JSON.parse(JSON.stringify(form.values.options));

														       options[index].value = target.value;

														       if (options[index].default)
															       form.setFieldValue('defaultValue', target.value)

														       form.setFieldValue('options', options)

													       }}
													       value={form.values.options[index].value}
													       autoComplete="off"/>
												</div>
											</div>

											<div className="col-2">
												<Toggle size="md" className="ml-1" checked={form.values.options[index].default} onChange={(checked) =>
												{
													const options = JSON.parse(JSON.stringify(form.values.options));

													form.setFieldValue('defaultValue', '');

													for (let i = 0; i < options.length; i++)
														options[i].default = false;

													options[index].default = checked;

													if (checked)
														form.setFieldValue('defaultValue', options[index].value);

													form.setFieldValue('options', options)
												}}/>
											</div>
											<div className="col-4 text-right">
												{
												(form.values.options.length > 1) &&
													<i role="button" className={`zwicon-trash text-danger h3 ml-1`} style={{'paddingTop': '0.4rem'}} onClick={() =>
													{
														const options = JSON.parse(JSON.stringify(form.values.options));
														options.splice(index, 1);
														form.setFieldValue('options', options)
													}}/>
												}
											</div>
										</div>))
									}


									<div className="form-input">
										{
										((form.touched.options && form.errors.options)) &&
											<div className="invalid-feedback" style={{display: 'block'}}>
												{(Array.isArray(form.errors.options) ? JSON.stringify(form.errors.options[0]) : form.errors.options)}
											</div>
										}
									</div>
								</div>
							}

							<div className="text-right py-4 col-12">
								<button type="submit" className="btn button btn-theme submitButton">{quickPanel.buttonTitle}</button>
							</div>
						</div>
					</form>
				</div>
			</SimpleBar>
		</QuickPanel>

		<Modal
		show={modal.show}
		close={modal.close}
		title={'Confirm Remove Field'}
		scrollable={true}
		size={"md"}
		centered={false}
		confirmButtons={true}
		buttonName={'Confirm Remove'}
		buttonClass={'btn-danger'}
		closeAction={() =>
		{
			setModal({...modal, show: false})
		}}
		confirmedAction={() =>
		{
			removeField(modal.data.id);
		}}
		>
			Confirm Remove Form Field? In ALL Zoners,This field Will Be Removed, THIS CANNOT BE UNDONE!!!
		</Modal>

	</>
	);
}

export default FieldPage;