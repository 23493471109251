import { SelectPicker, Table } from "rsuite";
import { KeyboardEvent, useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash";
import SimplePagination, { PaginationData } from "./SimplePagination";

const { Column, HeaderCell, Cell } = Table;

export const PAGINATION_OPTIONS = [
    { label: "20 ITEMS", value: 20 },
    { label: "50 ITEMS", value: 50 },
    { label: "100 ITEMS", value: 100 },
    { label: "200 ITEMS", value: 200 }
];

export interface SimpleTableData {
    key: string;
    headerLabel: string;
    width?: number;
    customColumn?: boolean;
}

interface SimpleTableProps {
    schema: Array<SimpleTableData>;
    data: Array<any>;
    customCellRenderer?: (schemaKey: string, rowData: any) => JSX.Element;
    onSearchChange?: (searchValue: string) => void;

    paginationData?: PaginationData;
    onPaginationChange?: (paginationData: Partial<PaginationData>) => void;
    totalRow?: number;

    fixedRightColumns?: Array<string>;
}

const SimpleTable = ({
    schema,
    data,
    customCellRenderer,
    onSearchChange,
    paginationData,
    onPaginationChange,
    totalRow = 0,
    fixedRightColumns
}: SimpleTableProps) => {
    const debouncedSearch = useRef(
        debounce(async (searchValue: string) => {
            onSearchChange && onSearchChange(searchValue);
        }, 1000)
    ).current;

    useEffect(() => {
        return () => debouncedSearch.cancel();
    }, [debouncedSearch]);

    const updatePagination = useCallback(
        (paginationParam: Partial<PaginationData>) => {
            onPaginationChange && onPaginationChange(paginationParam);
        },
        [onPaginationChange]
    );

    return (
        <div className="row">
            <div className="tableContainer col">
                {onSearchChange || (onPaginationChange && paginationData) ? (
                    <div
                        className={`d-flex mb-5 filter align-items-center w-100 ${
                            !onSearchChange ? "justify-content-end" : "justify-content-between"
                        }`}
                    >
                        {onSearchChange ? (
                            <div className="pageSearch w-100">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
                                        debouncedSearch((e.target as HTMLInputElement).value)
                                    }
                                />
                            </div>
                        ) : null}
                        {onPaginationChange && paginationData ? (
                            <div className="pageSize ml-3">
                                <SelectPicker
                                    className={`select pageLength`}
                                    menuClassName={`selectMenu`}
                                    data={PAGINATION_OPTIONS}
                                    searchable={false}
                                    cleanable={false}
                                    value={paginationData.rowPerPage}
                                    onChange={(value) => {
                                        if (value === null) return;
                                        updatePagination({
                                            totalPages: Math.ceil(totalRow / value),
                                            currentPage: 1,
                                            rowPerPage: value
                                        });
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}

                <Table
                    height={500}
                    headerHeight={50}
                    rowHeight={60}
                    data={data}
                    affixHorizontalScrollbar // scroll only works when all column have set width
                >
                    {schema.map((schemaItem) => {
                        const { key, headerLabel, width, customColumn } = schemaItem;
                        return (
                            <Column
                                key={key}
                                flexGrow={width ? 0 : 1}
                                width={width}
                                verticalAlign="middle"
                                align="left"
                                fixed={fixedRightColumns?.includes(key) ? "right" : false}
                            >
                                <HeaderCell verticalAlign="top">{headerLabel}</HeaderCell>
                                {customCellRenderer && customColumn ? (
                                    <Cell dataKey={key}>
                                        {(rowData) => customCellRenderer(key, rowData)}
                                    </Cell>
                                ) : (
                                    <Cell dataKey={key} />
                                )}
                            </Column>
                        );
                    })}
                </Table>

                {onPaginationChange && paginationData && paginationData.totalPages > 1 ? (
                    <SimplePagination
                        pagination={paginationData}
                        onChangePagination={updatePagination}
                        totalRow={totalRow}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default SimpleTable;
