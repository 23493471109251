enum AccessStatus
{
	ALLOW = 'ALLOW',
	DENY = 'DENY'
}

type ApprovalInfo = {
	zone: string;
	permitID: string;
	startDateTime: string|null;
	endDateTime: string|null;
	status: string;
	access: AccessStatus;
	workers: []
}

type Worker = {
	internalCardID: string;
	contractorCode: string;
	contractor: string;
	chineseName: string;
	englishName: string;
	contactPhone: string;
}

type ApprovalFormFields = {
	id:string;
	projectID: string;
	zoneID: string;
	access: AccessStatus;
	startDateTime: Date|null;
	endDateTime: Date|null;
	workers: any
}

export {AccessStatus};
export type {Worker, ApprovalFormFields,ApprovalInfo};