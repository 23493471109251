import React, {createContext, useEffect, useState} from 'react';

type AlertState={
    show: boolean;
    type: string;
    message: string;
}

type LoadingState={
    show:boolean;
}

type ComponentContextState = {
    alert:AlertState;
    loading:LoadingState;
    setAlert:(state:AlertState)=>void;
    setLoading:(state:LoadingState)=>void;
    resetComponentContext:()=>void;
}

const defaultValues: ComponentContextState = {
    alert:
    {
        show:false,
        type:'',
        message:''
    },
    loading:
    {
        show:false,
    },
    setAlert:(state)=>{},
    setLoading:(state)=>{},
    resetComponentContext:()=>{}
}

export const ComponentContext = createContext<ComponentContextState>(defaultValues);

const ComponentContextProvider=(props:any)=>
{

    const initialAlertState = {
        show: false,
        type: '',
        message: '',
    };

    const initialLoadingState = {
        show: false,
    };

    const [alert, setAlert] = useState(defaultValues.alert);
    const [loading, setLoading] = useState(defaultValues.loading);

    const resetComponentContext = () =>
    {
        setAlert(defaultValues.alert)
        setLoading(defaultValues.loading)
    }

    return (
    <ComponentContext.Provider value={{
        loading,
        alert,
        setLoading,
        setAlert,
        resetComponentContext
    }}>
        {props.children}
    </ComponentContext.Provider>
    );
}

export default ComponentContextProvider;