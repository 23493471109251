import React, {useCallback, useContext, useEffect, useState} from "react";
import {DateRangePicker, SelectPicker, TagPicker} from "rsuite";
import moment from 'moment';

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";

import {ApprovalInfo} from "../../types/ApprovalForm";

import DataTable from "../DataTable";
import {TabContent, TabContentItem, TabHeader, TabHeaderItem, TabPane} from "../TabPane";

type ApprovalDetailProps = {
	detail: ApprovalInfo;
}

const ApprovalDetail = ({detail}: ApprovalDetailProps) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main} = useContext(MainContext);

	const [activeTab, setActiveTab] = useState(0);

	const [zones, setZones] = useState([]);

	const [workers, setWorkers] = useState([]);

	const [workerData, setWorkerData] = useState<any>({});

	const [table, setTable] = useState({
		fetched: false,
		schema: [
			{key: 'internalCardId', name: 'Worker ID', width: 120},
			{key: 'chineseName', name: 'Chinese Name', width: 150},
			{key: 'englishName', name: 'English Name', width: 200},
			{key: 'contractor', name: 'Contractor', width: 350},
		]
	})

	useEffect(() =>
	{

	}, [])

	return <>
		<div className="row pt-3 pb-4">
			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Zone</label>
					<div className="colors light-2">{detail.zone}</div>
				</div>
			</div>

			{<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Permit ID</label>
					<div className="colors light-2">{detail.permitID}</div>
				</div>
			</div>}

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Status</label>
					<div className="colors light-2">{detail.status}</div>
				</div>
			</div>

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Access</label>
					<div className="colors light-2">
						{detail.access}
					</div>
				</div>
			</div>

			{/*<div className="col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5 fonts">Valid Through</label>
					<div className="colors light-2 fonts">
						{detail.startDateTime} - {detail.endDateTime}
					</div>
				</div>
			</div>*/}

			<div className="col-12">
				<TabPane>
					{
						<TabHeader filled={false}>
							<TabHeaderItem index={0} active={(activeTab === 0)} removable={false} onActive={() =>
							{
								setActiveTab(0)
							}}>
								Workers
							</TabHeaderItem>
						</TabHeader>
					}
					<TabContent>
						<TabContentItem index={0} active={(activeTab === 0)}>
							<div className="col-12">
								<DataTable
								height={300}
								fetched={table.fetched}
								dataSource={`LOCAL`}
								data={detail.workers}
								schema={table.schema}
								pagination={false}
								sortable={false}
								searchable={false}
								viewable={false}
								insertable={false}
								editable={false}
								removable={false}
								onViewButtonClick={(rowData) =>
								{
								}}
								onDataFetch={() =>
								{
								}}
								onFetched={() =>
								{
									setTable({...table, fetched: true})
								}}
								onRemoveButtonClick={(rowData) =>
								{
								}}
								/>
							</div>
						</TabContentItem>

					</TabContent>
				</TabPane>
			</div>
			{/*<div className="col-12">
				<DataTable
				height={300}
				fetched={table.fetched}
				dataSource={`LOCAL`}
				data={detail.workers}
				schema={table.schema}
				pagination={false}
				sortable={false}
				searchable={false}
				viewable={false}
				insertable={false}
				editable={false}
				removable={false}
				onViewButtonClick={(rowData) =>
				{
				}}
				onDataFetch={() =>
				{
				}}
				onFetched={() =>
				{
					setTable({...table, fetched: true})
				}}
				onRemoveButtonClick={(rowData) =>
				{
				}}
				/>
			</div>*/}
		</div>
	</>
}

export default ApprovalDetail;