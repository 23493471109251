import React, {useContext} from 'react';
import {useCookies} from "react-cookie";

import {MainContext} from "../contexts/MainContext";
import useSso from "../hooks/use-sso";

const Header = (props: any) =>
{
	const {main,logout, setMainContext} = useContext(MainContext);
	const [cookies, setCookie, removeCookie] = useCookies(['token'])

	const {ssoLogoutUrl} = useSso();

	const toggleMenu = () =>
	{
		document.getElementById('mainMenu')?.classList.toggle("sidebar--active");
		document.getElementById('mainmenu-backdrop')?.classList.toggle("active");
	}

	return (
	<header className="header">
		<div className="header__main">
			<i className="navigation-toggle zwicon-hamburger-menu d-xl-none" onClick={toggleMenu}></i>

			<div className={`logo d-none d-md-flex mt-3 align-items-center ${props.sidebar}`}>
				{/*<Link to="/">*/}
				{/*<div className="mr-2">
                    <img className="mr-2" src={`${process.env.PUBLIC_URL}/images/favicon.png`}
                         style={{width: '2rem'}} alt={"logo"}/>
                </div>*/}
				<div className="name">{main.title}</div>
				{/*</Link>*/}
			</div>

			<ul className="top-nav align-items-center">
				<li className={`dropdown d-none d-sm-block ${props.sidebar}`}>
					{main.username}
				</li>
				<li className={`dropdown d-none d-sm-block ${props.sidebar}`}>
					{/* <a href={ssoLogoutUrl} data-toggle="dropdown"><i className="zwicon-sign-out"></i></a> */}
					<a data-toggle="dropdown" onClick={logout}><i className="zwicon-sign-out"></i></a>
				</li>
			</ul>
		</div>
	</header>
	);
};

export default Header;