import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { MainContext } from "../contexts/MainContext";
import { ComponentContext } from "../contexts/ComponentContext";

import Modal from "../components/Modal";
import DataTable from "../components/DataTable";
import API from "../helpers/API";

const ZonePage = (props: any) => {
    const { alert, setAlert } = useContext(ComponentContext);
    const { main, resetToken } = useContext(MainContext);

    const { id } = useParams();

    const navigate = useNavigate();

    const [table, setTable] = useState({
        fetched: false
    });

    const [projectTable, setProjectTable] = useState({
        fetched: false
    });

    const [modal, setModal] = useState({
        show: false,
        close: false,
        loading: true,
        data: {}
    });

    const [removeModal, setRemoveModal] = useState({
        show: false,
        close: false,
        loading: true,
        data: { id: "" }
    });

    const [projects, setProjects] = useState({
        schema: [],
        data: []
    });

    const [isMachine, setIsMachine] = useState<any>({});
    const [isControlZone, setIsControlZone] = useState<any>({});

    const createZone = useCallback(
        async (data: any) => {
            setModal({
                ...modal,
                loading: true
            });

            const isOperator = data.zoneName in isMachine ? isMachine[data.zoneName] : false;
            const isCZ = data.zoneName in isControlZone ? isControlZone[data.zoneName] : true;

            API.call({
                url: `${main.apiUrl}/zones/`,
                options: {
                    method: "post",
                    credentials: "include",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        projectID: id,
                        name: data.zoneName,
                        isMachine: isOperator,
                        isControlZone: isCZ
                    })
                },
                successCallback: (res) => {
                    setModal({
                        ...modal,
                        loading: false,
                        close: true
                    });

                    setAlert({
                        ...alert,
                        show: true,
                        type: res.message.type,
                        message: res.message.content
                    });

                    setTable({ ...table, fetched: false });
                },
                errorCallback: (error) => {
                    setModal({
                        ...modal,
                        loading: false
                    });

                    setAlert({
                        ...alert,
                        show: true,
                        type: error.message.type,
                        message: error.message.content
                    });
                },
                resetToken: resetToken
            });
        },
        [modal, isMachine, isControlZone]
    );

    const removeZone = async (id: string) => {
        API.call({
            url: `${main.apiUrl}/zones/`,
            options: {
                method: "delete",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: id })
            },
            successCallback: (res) => {
                setAlert({
                    ...alert,
                    show: true,
                    type: res.message.type,
                    message: res.message.content
                });

                setTable({ ...table, fetched: false });
            },
            errorCallback: (error) => {
                setAlert({
                    ...alert,
                    show: true,
                    type: error.message.type,
                    message: error.message.content
                });
            },
            resetToken: resetToken
        });
    };

    const updateModal = useCallback(
        (properties: any) => {
            console.log("UPDATING MODAL");

            setModal({
                ...modal,
                ...properties
            });
        },
        [modal]
    );

    const updateRemoveModal = useCallback(
        (properties: any) => {
            console.log("UPDATING REMOVE MODAL");

            setRemoveModal({
                ...removeModal,
                ...properties
            });
        },
        [removeModal]
    );

    const fetchProjectDetail = useCallback(() => {
        API.call({
            url: `${main.apiUrl}/zones/`,
            options: {
                method: "post",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    projectID: id
                })
            },
            successCallback: (res) => {
                setModal({
                    ...modal,
                    loading: false,
                    close: true
                });

                setAlert({
                    ...alert,
                    show: true,
                    type: res.message.type,
                    message: res.message.content
                });

                setTable({ ...table, fetched: false });
            },
            errorCallback: (error) => {
                setModal({
                    ...modal,
                    loading: false
                });

                setAlert({
                    ...alert,
                    show: true,
                    type: error.message.type,
                    message: error.message.content
                });
            },
            resetToken: resetToken
        });
    }, [main]);

    return (
        <>
            <section className={`content ${props.sidebar}`}>
                <header className="content__title">
                    <h1 className="mr-auto">
                        Zones<small>List Of Zones With Control Zones</small>
                    </h1>
                    <button
                        className="btn button btn-theme"
                        onClick={(e) => {
                            setModal({ ...modal, show: true });
                            setProjectTable({ ...projectTable, fetched: false });
                        }}
                    >
                        Add Zone
                    </button>
                </header>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <DataTable
                                            fetched={table.fetched}
                                            dataSource={`REMOTE`}
                                            pagination={true}
                                            sortable={true}
                                            searchable={true}
                                            viewable={true}
                                            insertable={false}
                                            editable={false}
                                            removable={true}
                                            dataUrl={`${main.apiUrl}/zones?projectID=${id}`}
                                            onViewButtonClick={(rowData) => {
                                                navigate(`/${id}/${rowData._id}/workers`);
                                            }}
                                            onDataFetch={() => {}}
                                            onFetched={() => {
                                                setTable({ ...table, fetched: true });
                                            }}
                                            onRemoveButtonClick={(rowData) => {
                                                setRemoveModal({
                                                    ...removeModal,
                                                    show: true,
                                                    data: { id: rowData._id }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                show={modal.show}
                close={modal.close}
                loading={false}
                title={"Attend Zone List"}
                scrollable={false}
                size={"lg"}
                closeButton={true}
                confirmButtons={false}
                closeAction={() => {
                    setIsMachine({});
                    setIsControlZone({});
                    updateModal({ show: false, close: false });
                }}
                centered={true}
            >
                {modal.show && (
                    <DataTable
                        fetched={projectTable.fetched}
                        rowHeight={90}
                        dataSource={`REMOTE`}
                        pagination={true}
                        sortable={true}
                        searchable={true}
                        viewable={false}
                        insertable={true}
                        editable={false}
                        removable={false}
                        machine={isMachine}
                        controlZone={isControlZone}
                        toggleMachine={(rowData, checked) => {
                            const data = {} as any;

                            data[rowData.zoneName] = checked;

                            setIsMachine(data);
                        }}
                        toggleControlZone={(rowData, checked) => {
                            const data = {} as any;

                            data[rowData.zoneName] = checked;

                            setIsControlZone(data);
                        }}
                        dataUrl={`${main.apiUrl}/zones/fetch?projectID=${id}`}
                        onDataFetch={() => {}}
                        onFetched={() => {
                            updateModal({ loading: false });
                            setProjectTable({ ...projectTable, fetched: true });
                        }}
                        onInsertButtonClick={(rowData) => {
                            console.log(rowData);
                            createZone(rowData);
                        }}
                    />
                )}
            </Modal>

            <Modal
                show={removeModal.show}
                close={removeModal.close}
                title={"Confirm Remove Zone"}
                scrollable={true}
                size={"md"}
                centered={false}
                confirmButtons={true}
                buttonName={"Confirm Remove"}
                buttonClass={"btn-danger"}
                closeAction={() => {
                    updateRemoveModal({ show: false });
                }}
                confirmedAction={() => {
                    removeZone(removeModal.data.id);
                }}
            >
                Confirm Remove Zone? All Related
                <br />
                <br />
                Approval List
                <br />
                Zoners
                <br />
                Worker List
                <br />
                SMS Phone List
                <br />
                AI Cameras
                <br />
                Machines
                <br />
                Gas Sensors
                <br />
                Env Sensors
                <br />
                ELocks
                <br />
                <br />
                Will Also Be Deleted, THIS CANNOT BE UNDONE!!!
            </Modal>
        </>
    );
};

export default ZonePage;
