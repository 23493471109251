import React, {useContext} from 'react';

import {ComponentContext} from "../contexts/ComponentContext";

type LoadingProps={
	componentLoader:boolean;
	loading?:boolean;
	className?:string;
}

const Loading = (props: LoadingProps) =>
{
	const {loading} = useContext(ComponentContext);

	return (
		<>
			{
				(function ()
				{
					if (props.componentLoader)
					{
						return (props.loading) &&
							<div className={`${props.className} page-loader component-loader`}>
								<div className="loader">
									<i></i>
									<i></i>
									<i></i>
									<i></i>
									<i></i>
									<i></i>
								</div>
							</div>
					}

					return (loading.show) && <div className={`${props.className} page-loader`}>
						<div className="loader">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
					</div>

				})()
			}
		</>
	);
}

export default Loading;