import React, {useCallback, useContext, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import moment from 'moment';

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Modal from '../components/Modal';
import DataTable from "../components/DataTable";
import ApprovalForm from "../components/Approval/Form";

import {AccessStatus, ApprovalFormFields, ApprovalInfo} from "../types/ApprovalForm";
import ApprovalDetail from "../components/Approval/Detail";
import API from "../helpers/API";

const defaultFormValues: ApprovalFormFields = {
	id: '',
	projectID: '',
	zoneID: '',
	startDateTime: moment().toDate(),
	endDateTime: moment().toDate(),
	access: AccessStatus.ALLOW,
	workers: [],
}

const ApprovalPage = (props: any) =>
{
	const {alert, setAlert, loading, setLoading} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);

	const {id} = useParams();

	const navigate = useNavigate();

	const [table, setTable] = useState({
		fetched: false
	})

	const [approvalDetail, setApprovalDetail] = useState<ApprovalInfo>({
		zone: '',
		permitID: '',
		startDateTime: null,
		endDateTime: null,
		status: '',
		access: AccessStatus.ALLOW,
		workers: []
	});

	const [modal, setModal] = useState({
		show: false,
		close: false,
		loading: false,
		title: 'Create Approval Form',
		data: {}
	});

	const [viewModal, setViewModal] = useState({
		show: false,
		close: false,
		loading: false,
		data: {}
	});

	const [removeModal, setRemoveModal] = useState({
		show: false,
		close: false,
		loading: true,
		data: {id: ''}
	});

	const form = useFormik({
		initialValues: defaultFormValues,

		validationSchema: Yup.object({
			zoneID: Yup.string().required("Please Select a Zone For The List").min(1, "Zone ID Too Short"),
			workers: Yup.array().min(1, 'Please Select At Least 1 worker to the list')
		}),

		enableReinitialize: true,

		validateOnChange: false,

		onSubmit: (values) =>
		{
			if (values.id === '')
				createApproval(values);
			else
				updateApproval(values);
		},
	})

	const closeModal = useCallback(() =>
	{
		form.resetForm();
		setModal({...modal, show: false})
	}, [modal]);

	const closeViewModal = useCallback(() =>
	{
		setViewModal({...viewModal, show: false})
	}, [viewModal]);

	const updateRemoveModal = useCallback((properties: any) =>
	{
		console.log("UPDATING REMOVE MODAL");

		setRemoveModal({
			...removeModal,
			...properties
		})

	}, [removeModal])

	const fetchWorkers = useCallback(async () =>
	{
		const response = await fetch(`${main.apiUrl}/attend/workers`, {
			method: 'post',
			credentials: 'include',
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({projectID: id})
		});

		const data = await response.json().catch(error => console.log("REQUEST ERROR:", error));

		return

		/*setModal({...modal, show: false})*/

		/*if (data.statusCode === 200)
		{
			setAlert({
				...alert,
				show: true,
				type: data.message.type,
				message: data.message.content
			})

			setTable({...table, fetched: false})
		}
		else
		{
			setAlert({
				...alert,
				show: true,
				type: data.message.type,
				message: data.message.content
			})
		}*/
	}, [])

	const fetchApproval = useCallback(async (approvalID: any) =>
	{
		const response = await fetch(`${main.apiUrl}/approvals/fetch`, {
			method: 'post',
			credentials: 'include',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({id: approvalID})
		});

		let error = null

		const res = await response.json().catch(err => error = err);

		console.log(res.data);

		console.log(error);

		if (error === null)
		{
			if (res.statusCode === 200)
			{
				return res.data;
			}
			else
			{
				console.log(res);

				setAlert({
					...alert,
					show: true,
					type: 'danger',
					message: res.message.content
				})

				setModal({...modal, loading: false})
			}
		}
		else
		{
			setAlert({
				...alert,
				show: true,
				type: 'danger',
				message: error
			})

			setModal({...modal, loading: false})
		}
	}, [modal, table]);

	const createApproval = useCallback(async (values: any) =>
	{
		setModal({...modal, loading: true})

		let formData = JSON.parse(JSON.stringify(values));

		formData.projectID = id;

		API.call({
			url: `${main.apiUrl}/approvals`,
			options: {
				method: 'post',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(formData)
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: 'success',
					message: res.message.content
				})

				setModal({...modal, loading: false, close: true})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})

				setModal({...modal, loading: false})
			},
			resetToken: resetToken
		});
	}, [modal, table]);

	const updateApproval = useCallback(async (values: any) =>
	{
		setModal({...modal, loading: true})

		let formData = JSON.parse(JSON.stringify(values));

		formData.projectID = id;

		API.call({
			url: `${main.apiUrl}/approvals`,
			options: {
				method: 'put',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(formData)
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: 'success',
					message: res.message.content
				})

				setModal({...modal, loading: false, close: true})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: 'danger',
					message: error.message.content
				})

				setModal({...modal, loading: false})
			},
			resetToken: resetToken
		});

	}, [modal, table]);

	const setApprovalForm = useCallback(async (rowData: any) =>
	{
		const approval = await fetchApproval(rowData.id);

		console.log(approval.zoneID);

		form.setValues({
			id: approval.id,
			projectID: id as string,
			zoneID: approval.zoneID,
			startDateTime: moment(approval.startDateTime).toDate(),
			endDateTime: moment(approval.endDateTime).toDate(),
			access: approval.access,
			workers: approval.workers,
		})

		setModal({...modal, title: 'Edit Approval Form', show: true})
	}, [modal])

	const setApprovalInfo = useCallback(async (rowData: any) =>
	{
		const approval = await fetchApproval(rowData.id);

		setApprovalDetail({
			zone: approval.zone,
			permitID: approval.permitID,
			startDateTime: moment(approval.startDateTime).format('YYYY-MM-DD HH:mm'),
			endDateTime: moment(approval.endDateTime).format('YYYY-MM-DD HH:mm'),
			status: approval.status,
			access: approval.access,
			workers: approval.workers
		})

		setViewModal({...modal, show: true})

	}, [viewModal])

	const removeApproval = async (id: string) =>
	{
		API.call({
			url: `${main.apiUrl}/approvals`,
			options: {
				method: 'delete',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({id: id})
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}

	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Approvals<small>List of Approval For Permissions To Work In Different Control
					Zone</small></h1>
				<button className="btn button btn-theme" onClick={e =>
				{
					setModal({...modal, title: 'Create Approval Form', show: true})
				}}>
					Create Approval
				</button>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<DataTable
									fetched={table.fetched}
									dataSource={`REMOTE`}
									pagination={true}
									sortable={true}
									searchable={true}
									viewable={true}
									insertable={false}
									editable={true}
									removable={true}
									dataUrl={`${main.apiUrl}/approvals?projectID=${id}`}
									onViewButtonClick={async (rowData) =>
									{
										setApprovalInfo(rowData);
									}}
									onEditButtonClick={async (rowData) =>
									{
										setApprovalForm(rowData);
									}}
									onDataFetch={() =>
									{
									}}
									onFetched={() =>
									{
										setTable({...table, fetched: true})
									}}
									onRemoveButtonClick={(rowData) =>
									{
										setRemoveModal({...removeModal, show: true, data: {id: rowData.id}})
									}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<Modal
		show={modal.show}
		close={modal.close}
		loading={modal.loading}
		title={modal.title}
		scrollable={true}
		size={"lg"}
		closeButton={true}
		confirmButtons={false}
		closeAction={closeModal}
		centered={true}
		>
			{(modal.show) &&
				<>
					<ApprovalForm projectID={id} form={form}/>
				</>
			}
		</Modal>

		<Modal
		show={viewModal.show}
		close={viewModal.close}
		loading={viewModal.loading}
		title={"View Approval Detail"}
		scrollable={true}
		size={"lg"}
		closeButton={true}
		confirmButtons={true}
		closeAction={closeViewModal}
		centered={true}
		>
			{(viewModal.show) &&
				<>
					<ApprovalDetail detail={approvalDetail}/>
				</>
			}
		</Modal>

		<Modal
			show={removeModal.show}
			close={removeModal.close}
			title={'Confirm Remove Approval'}
			scrollable={true}
			size={"md"}
			centered={false}
			confirmButtons={true}
			buttonName={'Confirm Remove'}
			buttonClass={'btn-danger'}
			closeAction={() =>
			{
				setRemoveModal({...removeModal, show: false})
			}}
			confirmedAction={() =>
			{
				removeApproval(removeModal.data.id);
			}}
		>
			Confirm Remove Approval?<br/>THIS CANNOT BE UNDONE!!!
		</Modal>
	</>
	);
}

export default ApprovalPage;