import React, {useCallback, useContext, useEffect, useState} from "react";
import {SelectPicker} from "rsuite";

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";
import API from "../../helpers/API";

type ELockFormProps = {
  projectID: string | undefined;
  form: any
}

interface ELock {
  deviceId: string;
  deviceSn: string;
}

const ELockForm = ({projectID, form}: ELockFormProps) => {
  const {alert, setAlert} = useContext(ComponentContext);
  const {main, resetToken} = useContext(MainContext);

  const [zones, setZones] = useState([]);

  const [eLocks, setELocks] = useState([]);
  const[eLockSn, setELockSn] = useState({});

  const fetchZones = useCallback(async () => {
    const params = new URLSearchParams();

    params.append('projectID', projectID as string);
    params.append('page', '1');
    params.append('size', '1000000');

    API.call({
      url: `${main.apiUrl}/zones?${params.toString()}`,
      options: {
        method: 'get',
        credentials: 'include',
        headers: {"Content-Type": "application/json"},
      },
      successCallback: (res) => {
        setZones(res.data.records.map((zone: any) => ({label: zone.name, value: zone._id})));
      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })
      },
      resetToken: resetToken
    });
  }, []);

  useEffect(() => {
    fetchZones();
  }, [])

    const fetchELocks = useCallback(async () => {
    const params = new URLSearchParams();
    params.append('projectID', projectID as string);
    params.append('page', '1');
    params.append('size', '1000000');

    API.call({
      url: `${main.apiUrl}/e-locks/fetch?${params.toString()}`,
      options: {
        method: 'get',
        credentials: 'include',
        headers: {"Content-Type": "application/json"},
      },
      successCallback: (res) => {
        setELocks(
          res.data.records.map((eLock: any) => ({
            label: eLock.deviceId, 
            value: eLock.deviceId
          }))
        );
        const eLockSnMapping: Record<string, string> = res.data.records.reduce((acc: Record<string, string>, eLock: ELock) => {
          acc[eLock.deviceId] = eLock.deviceSn;
          return acc;
        }, {});
        setELockSn(eLockSnMapping);

      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })
      },
      resetToken: resetToken
    });
  }, []);

  useEffect(() => {
    fetchELocks();
  }, [])

  return <>
    <form onSubmit={form.handleSubmit}>
      <div className="row py-4">
        <div className="col-12">
          <div className="form-group">
            <label className="mb-3">Zone</label>
            <SelectPicker
              className={'select w-100'}
              menuClassName={`selectMenu`}
              data={zones}
              searchable={false}
              cleanable={false}
              appearance="subtle"
              value={form.values.zoneID}
              onChange={(value) => {
                form.setFieldValue('zoneID', value);
              }}
            />
            {((form.touched.zoneID && form.errors.zoneID)) && (
              <div
                className="invalid-feedback"
                style={{display: 'block'}}
              >
                {form.errors.zoneID}
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="mb-3">ELock Device ID</label>
            <SelectPicker
            className={'select w-100'}
						menuClassName={`selectMenu`}
						data={eLocks}
						searchable={true}
						cleanable={false}
						appearance="subtle"
						value={form.values.eLockDeviceID}
            onChange={(value) => {
              form.setFieldValue('eLockDeviceID', value);
              form.setFieldValue('eLockDeviceSn', eLockSn[value as keyof typeof eLockSn]);
              console.log(form.values)
            }}
            />
          </div>
          {((form.touched.eLockDeviceID && form.errors.eLockDeviceID)) && (
            <div
              className="invalid-feedback"
              style={{display: 'block'}}
            >
              {form.errors.eLockDeviceID}
            </div>
          )}
        </div>
        <div className="col-12">
          <div className="weight-500 font-16 mb-3">ELock Device SN</div>
          <div className="form-input">
            <input
              id="eLockDeviceSn"
              name="eLockDeviceSn"
              type="text"
              className="form-control"
              onChange={form.handleChange}
              value={form.values.eLockDeviceSn}
              autoComplete="off"
              disabled/>
          </div>
          {((form.touched.eLockDeviceSn && form.errors.eLockDeviceSn)) && (
            <div
              className="invalid-feedback"
              style={{display: 'block'}}
            >
              {form.errors.eLockDeviceSn}
            </div>
          )}
        </div>
      </div>
      <div className="text-right">
        <button type="submit" className="btn button btn-theme">Submit</button>
      </div>
    </form>
  </>
}

export default ELockForm;