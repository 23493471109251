import React, {useContext, useEffect, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";
import useSso from "../hooks/use-sso";
import queryString from 'query-string';
import {useLocation} from "react-router-dom";

const LoginPage = () =>
{
	const location = useLocation();
	const {loading, setLoading, alert, setAlert} = useContext(ComponentContext);
	const {main,validateToken,setMainContext} = useContext(MainContext);

	const {ssoLoginUrl, ssoLoginRedirectUri} = useSso();

	useEffect(() => {
		if (location.search.length) {
			const values = queryString.parse(location.search.split('?')[1]);
			if (values.code) {
				const { code } = values;
				if (code) {
					loginSso(`${code}`).then();
				}
			}
		}
	}, [location.pathname]);

	const form = useFormik({
		initialValues: {
			username: '',
			password: '',
			failed: false,
		},
		validationSchema: Yup.object({
			username: Yup.string().required('Please Enter Username').min(1, 'Please Enter Username'),
			password: Yup.string().required('Please Enter Password').min(1, 'Please Enter Password')
		}),

		validateOnChange: false,

		onSubmit: (values: any) =>
		{
			login(values)
		}
	})

	const login = async (values: any) =>
	{
		setLoading({
			...loading,
			show: true,
		});

		const response = await fetch(`${main.apiUrl}/users/login`, {
			method: 'post',
			credentials: 'include',
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({username: values.username, password: values.password}),
		});

		let error = null

		const data = await response.json().catch(err => error = err);

		setLoading({
			...loading,
			show: false,
		});

		if (error !== null)
			console.log(error);

		if (data.statusCode !== 200)
		{
			setAlert({
				...alert,
				show: true,
				type: 'error',
				message: "No Access"
			})

			return form.setErrors({failed: data.message.content});
		}

		setMainContext({
			...main,
			username:data.data.username,
		})

		validateToken();
	}

	const loginSso = async (code: string) => {
		setLoading({
			...loading,
			show: true,
		});

		const response = await fetch(`${main.apiUrl}/users/login-sso`, {
			method: 'post',
			credentials: 'include',
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({ code, redirectUri: ssoLoginRedirectUri }),
		});

		let error = null

		const data = await response.json().catch(err => error = err);

		setLoading({
			...loading,
			show: false,
		});

		if (error !== null)
			console.log(error);

		if (data.statusCode !== 200)
		{
			setAlert({
				...alert,
				show: true,
				type: 'error',
				message: "No Access"
			})

			return form.setErrors({failed: data.message.content});
		}

		setMainContext({
			...main,
			username:data.data.username,
		})

		validateToken();
	}

	return (
	<div className="login">
		<div className="login__block active" id="login-main">
			<div className="login__header">
				<i className="zwicon-user-circle"></i>
				<div className="mt-2">Control Zone System</div>
			</div>
			<form onSubmit={form.handleSubmit}>
				<div className="login__body">
					<div className="login__inputs">
						<div className="form-group">
							<input type="text" name="username" className="form-control text-center"
										 placeholder="Username"
										 onChange={form.handleChange}
										 value={form.values.username}/>
						</div>

						<div className="form-group">
							<input type="password" name="password"
										 className="form-control text-center" placeholder="Password"
										 onChange={form.handleChange}
										 value={form.values.password}/>
						</div>

						{((form.touched.username && form.errors.username)) &&
							<div className="invalid-feedback"
									 style={{display: 'block'}}>{form.errors.username}</div>}

						{((form.touched.password && form.errors.password)) &&
							<div className="invalid-feedback"
									 style={{display: 'block'}}>{form.errors.password}</div>}

						{((form.errors.failed)) &&
							<div className="invalid-feedback"
									 style={{display: 'block'}}>{form.errors.failed}</div>}
					</div>

					<button type="submit" className="login__btn border-0 outline">Login</button>
					{/*<a href={ssoLoginUrl} className="login__btn border-0 outline">Login</a>*/}
				</div>
			</form>
		</div>
	</div>
	);
}

export default LoginPage;