import { useParams } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import MenuItem from "../components/MenuItem";

const ProjectLayout = (props: any) => {
    const { id } = useParams();

    //   const toggleSubMenu = (e: any) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     e.target.parentNode.classList.toggle("active");
    //   };

    return (
        <>
            <Header sidebar={props.sidebar} />

            <div className="main">
                {props.sidebar && (
                    <>
                        <Sidebar>
                            <li>
                                <MenuItem
                                    activeColor={"white"}
                                    icon={"arrow-left"}
                                    label={"Back To Project List"}
                                    location={`/`}
                                    end={true}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"blue"}
                                    icon={"grid"}
                                    label={"Zone List"}
                                    location={`/${id}/zones`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"Approvals"}
                                    location={`/${id}/approvals`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"AI Cameras"}
                                    location={`/${id}/ai-cameras`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"Machines"}
                                    location={`/${id}/machines`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"Gas Sensors"}
                                    location={`/${id}/gas-sensors`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"Env Sensors"}
                                    location={`/${id}/env-sensors`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"ELocks"}
                                    location={`/${id}/e-locks`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"red"}
                                    icon={"grid"}
                                    label={"SmartWatches"}
                                    location={`/${id}/smart-watches`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"GIS Map"}
                                    location={`/${id}/gis-map-list`}
                                />
                            </li>

                            <li>
                                <MenuItem
                                    activeColor={"yellow"}
                                    icon={"grid"}
                                    label={"Project Config"}
                                    location={`/${id}/project-config`}
                                />
                            </li>

                            {/*<li>
                        <MenuItem activeColor={"red"} icon={"note"} label={"News"} location={"/news"}/>
                    </li>

                    <li className="navigation__sub">
                        <a href="" data-mae-action="submenu-toggle"
                           onClick={toggleSubMenu}><i
                        className="zwicon-paper-prototype green"/>Website Content</a>
                        <ul style={{listStyleType: 'none'}}>

                            <li>
                                <MenuItem activeColor={"white"} icon={"web"}
                                          label={"General"}
                                          location={"/contents/general"}/>
                            </li>
                            <li>
                                <MenuItem activeColor={"white"} icon={"calendar-month"}
                                          label={"Pages"}
                                          location={"/contents/pages"}/>
                            </li>
                        </ul>
                    </li>*/}
                        </Sidebar>
                    </>
                )}
                {props.component}
            </div>
            <footer className="footer" style={{ padding: "2rem 1rem" }}>
                Admin Panel SIT v2.0.0
            </footer>
        </>
    );
};

export default ProjectLayout;
