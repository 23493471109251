import React, {useContext, useState, useCallback, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import DataTable from "../components/DataTable";
import API from "../helpers/API";
import {Toggle} from "rsuite";
import Modal from "../components/Modal";

const WorkerPage = (props: any) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);

	const {projectID, zoneID} = useParams();

	const navigate = useNavigate();

	const [table, setTable] = useState({
		fetched: false
	});
	const [isDenyAllWorkers, setIsDenyAllWorkers] = useState<boolean|null>(null);
	const [toggleIsDenyAllWorkersModal, setToggleIsDenyAllWorkersModal] = useState({
		show: false,
		close: false,
		loading: true,
		data: {id: ''}
	});

	const getProjectZones = useCallback(async () =>
	{
		API.call({
			url: `${main.apiUrl}/zones?projectID=${projectID}`,
			options: {
				method: 'get',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
			},
			successCallback: (res) =>
			{
				const zone = res.data.records.find((zone: any) => zone._id === zoneID);
				console.log('zone', zone)
				if (zone) {
					setIsDenyAllWorkers(zone.isDenyAllWorkers === 'YES');
				}
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}, []);

	const updateZoneIsDenyAllWorkers = useCallback(async (shouldDeny: boolean) =>
	{
		API.call({
			url: `${main.apiUrl}/zones/deny-all-workers/${shouldDeny ? 'yes' : 'no'}?projectID=${projectID}`,
			options: {
				method: 'post',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					projectID,
					id: zoneID,
				})
			},
			successCallback: (res) =>
			{
			},
			errorCallback: (error) =>
			{
			},
			resetToken: resetToken
		});
	}, []);

	const downloadCSV = useCallback(() =>
	{
		API.download({
			url: `${main.apiUrl}/workers/export?zoneID=${zoneID}`,
			options: {
				method: 'get',
				credentials: 'include',
			},
			successCallback: (blob) =>
			{
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = `ctrlList.csv`;
				document.body.appendChild(a);
				a.click();
				a.remove();
			},
			errorCallback: (error) =>
			{
				console.log(error);
			},
			resetToken: resetToken
		});
	}, []);
	
	useEffect(() => {
		getProjectZones().then();
	}, [])

	return (
		<>
			<section className={`content ${props.sidebar}`}>
				<header className="content__title">
					<h1 className="mr-auto">Zone Workers<small>List Of Workers Allowed/ Denied To Enter Control Zone </small></h1>
					<div className="d-flex align-items-center">
						{isDenyAllWorkers != null && (
							<div className="d-flex align-items-center mr-4">
								<p className="m-0 mr-2">Deny All Workers:</p>
								<Toggle
									checked={isDenyAllWorkers}
									style={{ margin: 0 }}
									onChange={() => {
										setToggleIsDenyAllWorkersModal({...toggleIsDenyAllWorkersModal, show: true, data: {id: 'test'}})
									}}
								/>
							</div>
						)}
						{/*<a href={`${main.apiUrl}/workers/export?zoneID=${zoneID}`} target={"_blank"}>*/}
						<button type="button" className="btn button btn-theme" onClick={downloadCSV}>
							Export
						</button>
						{/*</a>*/}
					</div>
				</header>

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col">
										<DataTable
											fetched={table.fetched}
											dataSource={`REMOTE`}
											pagination={true}
											sortable={true}
											searchable={true}
											viewable={false}
											insertable={false}
											editable={false}
											removable={false}
											dataUrl={`${main.apiUrl}/workers?zoneID=${zoneID}`}
											onDataFetch={() =>
											{
											}}
											onFetched={() =>
											{
												setTable({...table, fetched: true})
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Modal
				show={toggleIsDenyAllWorkersModal.show}
				close={toggleIsDenyAllWorkersModal.close}
				title={'Confirm?'}
				scrollable={true}
				size={"md"}
				centered={false}
				confirmButtons={true}
				buttonName={'Confirm Change'}
				buttonClass={'btn-danger'}
				closeAction={() => {
					setToggleIsDenyAllWorkersModal({...toggleIsDenyAllWorkersModal, show: false})
				}}
				confirmedAction={() => {
					setIsDenyAllWorkers(!isDenyAllWorkers);
					updateZoneIsDenyAllWorkers(!isDenyAllWorkers!).then();
				}}
			>
				Confirm update `Deny All Workers` to {!isDenyAllWorkers ? 'YES' : 'NO'}?
			</Modal>
		</>
	);
}

export default WorkerPage;