import React, {useContext} from 'react';
import {CSSTransition} from 'react-transition-group';

import {ComponentContext} from "../contexts/ComponentContext";

const Alert = (props: any) =>
{
	const {alert, setAlert} = useContext(ComponentContext)

	const closeAlert = () =>
	{
		setAlert({
			...alert,
			show: false,
		});
	}

	return (
	<CSSTransition classNames={{
		appear: 'active animate__animated',
		appearActive: 'active animate__animated animate__fadeInRight animate__faster',
		appearDone: 'active animate__animated animate__fadeInRight animate__faster',
		enter: 'active animate__animated animate__fadeInRight animate__faster',
		enterActive: 'active animate__animated animate__fadeInRight animate__faster',
		enterDone: 'active animate__animated animate__fadeInRight animate__faster',
		exit: 'active animate__animated animate__fadeOutRight animate__faster',
		exitActive: 'active animate__animated animate__fadeOutRight animate__faster',
		exitDone: 'active animate__animated animate__fadeOutRight animate__faster',
	}} onEntered={() => setTimeout(closeAlert, 5000)} in={alert.show} timeout={0}>
		<div className={`alerts alert alert-${(alert.type === 'error') ? 'danger' : alert.type}`} role="alert" onAnimationEnd={(e) =>
		{
			if (!alert.show)
			{
				const target = e.target as HTMLDivElement;

				target.classList.remove('active');

				setAlert({
					...alert,
					show: false
				})
			}
		}}>
			{alert.message}
		</div>
	</CSSTransition>
	);
};

export default Alert;