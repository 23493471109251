import React, {useCallback, useContext, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import SimpleBar from "simplebar-react";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import DataTable from "../components/DataTable";
import QuickPanel from "../components/QuickPanel";
import Modal from "../components/Modal";
import API from "../helpers/API";

type Phone = {
	_id: string;
	name: string;
	number: string;
}

type PhoneForm = {
	projectID: string;
	zoneID: string;
	phones: Phone[]
}

const defaultPhone: Phone = {
	_id: '',
	name: '',
	number: ''
}

const defaultFormValues: PhoneForm = {
	projectID: '',
	zoneID: '',
	phones: [defaultPhone]
}

const PhonePage = (props: any) =>
{
	const {alert, setAlert, loading, setLoading} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);

	const {projectID, zoneID} = useParams();

	const navigate = useNavigate();

	const [table, setTable] = useState({
		fetched: false
	})

	const [quickPanel, setQuickPanel] = useState({
		show: false,
		processing: false,
		close: false,
		title: 'CREATE NEW FIELD',
		buttonTitle: 'Create',
	});

	const [editPanel, setEditPanel] = useState({
		show: false,
		processing: false,
		close: false,
		title: 'EDIT Contact',
		buttonTitle: 'Update',
	});

	const [modal, setModal] = useState({
		show: false,
		close: false,
		loading: true,
		data: {id: ''}
	});

	const form = useFormik({
		initialValues: defaultFormValues,

		validationSchema: Yup.object({
			phones: Yup.array().min(1, 'Attribute Must Consist of at least ONE Property').of(Yup.object().shape({
				name: Yup.string().required("Please Enter Name").min(1, "Option Name Too Short"),
				number: Yup.string().required("Please Enter Number").min(1, "Option Name Too Short"),
			})),
		}),
		enableReinitialize: true,

		validateOnChange: false,

		onSubmit: (values) =>
		{
			createPhones(values);
			/*if (values.id === '')
				createApproval(values);
			else
				updateApproval(values);*/
		},
	})

	const editForm = useFormik({
		initialValues: {
			id: '',
			name: '',
			number: '',
		},

		validationSchema: Yup.object({
			name: Yup.string().required("Please Enter Name").min(1, "Option Name Too Short"),
			number: Yup.string().required("Please Enter Number").min(1, "Option Name Too Short"),
		}),
		enableReinitialize: true,

		validateOnChange: false,

		onSubmit: (values) =>
		{
			updatePhone(values);
		},
	});

	const createPhones = useCallback(async (values: any) =>
	{
		setQuickPanel({
			...quickPanel,
			processing: true,
		})

		values.projectID = projectID;
		values.zoneID = zoneID;

		API.call({
			url: `${main.apiUrl}/phones`,
			options: {
				method: 'post',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(values)
			},
			successCallback: (res) =>
			{
				form.resetForm();

				setQuickPanel({
					...quickPanel,
					processing: false,
					show: false,
					close: true,
				})

				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				form.resetForm();

				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});

	}, [quickPanel]);

	const updatePhone = useCallback(async (values: any) =>
	{
		setQuickPanel({
			...quickPanel,
			processing: true,
		})

		API.call({
			url: `${main.apiUrl}/phones`,
			options: {
				method: 'put',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(values)
			},
			successCallback: (res) =>
			{
				form.resetForm();

				setQuickPanel({
					...quickPanel,
					processing: false,
					show: false,
					close: true,
				})

				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				form.resetForm();

				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});

	}, [editPanel]);

	const removeContact = async (id: string) =>
	{
		API.call({
			url: `${main.apiUrl}/phones`,
			options: {
				method: 'delete',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({id: id})
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}

	const closeModal = useCallback(() =>
	{
		form.resetForm();
		setModal({...modal, show: false})
	}, [modal]);

	const addPhone = useCallback(() =>
	{
		form.values.phones.push({_id: '', name: '', number: ''});
		form.setFieldValue('phones', form.values.phones);
	}, [form.values.phones])

	const setPhoneName = useCallback((index: number, value: any) =>
	{
		form.values.phones[index]['name'] = value;
		form.setFieldValue('phones', form.values.phones);
	}, [form.values.phones])

	const setPhoneNumber = useCallback((index: number, value: any) =>
	{
		form.values.phones[index]['number'] = value;
		form.setFieldValue('phones', form.values.phones);
	}, [form.values.phones])

	const removePhone = useCallback((index: number) =>
	{
		form.values.phones.splice(index, 1)
		form.setFieldValue('phones', form.values.phones);
	}, [form.values.phones])

	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">SMS Phone List<small>List of Phone Number That Will Receive SMS Message When Violation Occur In Zone</small></h1>
				<button className="btn button btn-theme" onClick={e =>
				{
					form.resetForm();

					setQuickPanel({
						...quickPanel, show: true,
						title: 'ADD PHONE NUMBERS',
						buttonTitle: 'Create',
					})
				}}>
					Add Phone Numbers
				</button>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<DataTable
									fetched={table.fetched}
									dataSource={`REMOTE`}
									pagination={true}
									sortable={true}
									searchable={true}
									viewable={false}
									insertable={false}
									editable={true}
									removable={true}
									dataUrl={`${main.apiUrl}/phones?zoneID=${zoneID}`}
									onViewButtonClick={async (rowData) =>
									{

									}}
									onEditButtonClick={async (rowData) =>
									{
										console.log(rowData);

										editForm.setValues({
											id: rowData._id,
											name: rowData.name,
											number: rowData.number
										})

										setEditPanel({
											...editPanel,
											show: true,
										})
									}}
									onDataFetch={() =>
									{
									}}
									onFetched={() =>
									{
										setTable({...table, fetched: true})
									}}
									onRemoveButtonClick={(rowData) =>
									{
										setModal({...modal, show: true, data: {id: rowData._id}})
									}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<QuickPanel
		title={quickPanel.title}
		subtitle={''}
		show={quickPanel.show}
		processing={quickPanel.processing}
		close={quickPanel.close}
		closeAction={() =>
		{
			form.resetForm();
			setQuickPanel({...quickPanel, show: false})
		}}
		>
			<SimpleBar style={{maxHeight: '80vh'}}>
				<div className="px-5 py-4">
					<form onSubmit={form.handleSubmit} style={{maxHeight: '75vh'}}>
						<div className="row">

							<div className="col-12 mt-4 mb-5">
								<div className="row mb-4 justify-content-between">
									<div className="col-5">
										<div className="fonts weight-500 font-16 mb-2">Contacts</div>
									</div>

									<div className="col-4 text-right">
										<button type="button" className="button btn-sm btn-theme py-2 px-3"
										        onClick={(e) =>
										        {
											        addPhone();
										        }}
										>
											Add Contact
										</button>
									</div>
								</div>

								{
									((form.values.phones.length > 0) && form.values.phones.map((phone: any, index: number) =>
									<div className="row mb-3 pt-2 align-items-center">
										<div className="col-10">
											<div className="row">
												<div className="col-12 mb-3">
													<div className="form-input">
														<input type="text" className="form-control" name={`phones[${index}].name`}
														       placeholder="Name"
														       onChange={(e) =>
														       {
															       const target = e.target as HTMLInputElement;
															       setPhoneName(index, target.value)

														       }}
														       value={form.values.phones[index].name}
														       autoComplete="off"/>
													</div>
												</div>
												<div className="col-12 mb-3">
													<div className="form-input">
														<input type="text" className="form-control" name={`phones[${index}].number`}
														       placeholder="Phone Number"
														       onChange={(e) =>
														       {
															       const target = e.target as HTMLInputElement;
															       setPhoneNumber(index, target.value)

														       }}
														       value={form.values.phones[index].number}
														       autoComplete="off"/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-2 text-right">
											{
											(form.values.phones.length > 1) &&
												<i role="button" className={`zwicon-trash text-danger h3 ml-1`} style={{'paddingTop': '0.4rem'}} onClick={() =>
												{
													removePhone(index);
												}}/>
											}
										</div>
									</div>))
								}


								<div className="form-input">
									{
									((form.touched.phones && form.errors.phones)) &&
										<div className="invalid-feedback" style={{display: 'block'}}>
											{JSON.stringify(form.errors.phones)}
										</div>
									}
								</div>
							</div>

							<div className="text-right py-4 col-12">
								<button type="submit" className="btn button btn-theme submitButton">{quickPanel.buttonTitle}</button>
							</div>
						</div>
					</form>
				</div>
			</SimpleBar>
		</QuickPanel>

		<QuickPanel
		title={editPanel.title}
		subtitle={''}
		show={editPanel.show}
		processing={editPanel.processing}
		close={editPanel.close}
		closeAction={() =>
		{
			editForm.resetForm();
			setEditPanel({...editPanel, show: false})
		}}
		>
			<SimpleBar style={{maxHeight: '80vh'}}>
				<div className="px-5 py-4">
					<form onSubmit={editForm.handleSubmit} style={{maxHeight: '75vh'}}>
						<div className="row">
							<div className="col-12 mt-4 mb-5">
								<div className="row mb-4 justify-content-between">
									<div className="col-5">
										<div className="fonts weight-500 font-16 mb-2">Contacts</div>
									</div>
								</div>
								<div className="row mb-3 pt-2 align-items-center">

									<div className="col-12 mb-3">
										<div className="form-input">
											<input type="text" className="form-control" name={`name`}
											       placeholder="Name"
											       onChange={editForm.handleChange}
											       value={editForm.values.name}
											       autoComplete="off"/>
										</div>
									</div>
									<div className="col-12 mb-3">
										<div className="form-input">
											<input type="text" className="form-control" name={`number`}
											       placeholder="Phone Number"
											       onChange={editForm.handleChange}
											       value={editForm.values.number}
											       autoComplete="off"/>
										</div>
									</div>

									<div className="form-input">
										{
										((editForm.touched.name && editForm.errors.name)) &&
											<div className="invalid-feedback" style={{display: 'block'}}>
												{JSON.stringify(editForm.errors.name)}
											</div>
										}
									</div>

									<div className="form-input">
										{
										((editForm.touched.number && editForm.errors.number)) &&
											<div className="invalid-feedback" style={{display: 'block'}}>
												{JSON.stringify(editForm.errors.number)}
											</div>
										}
									</div>
								</div>

								<div className="text-right py-4 col-12 pr-0">
									<button type="submit" className="btn button btn-theme submitButton">{editPanel.buttonTitle}</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</SimpleBar>
		</QuickPanel>

		<Modal
		show={modal.show}
		close={modal.close}
		title={'Confirm Remove Contact'}
		scrollable={true}
		size={"md"}
		centered={false}
		confirmButtons={true}
		buttonName={'Confirm Remove'}
		buttonClass={'btn-danger'}
		closeAction={closeModal}
		confirmedAction={() =>
		{
			removeContact(modal.data.id);
		}}
		>
			Confirm Remove Contact? This Cannot Be Undone.
		</Modal>
	</>
	);
}

export default PhonePage;