interface SimpleDataActionBtnsProps {
    onClickView?: () => void;
    onClickAdd?: () => void;
    onClickEdit?: () => void;
    onClickRemove?: () => void;
}

const SimpleDataActionBtns = ({
    onClickView,
    onClickAdd,
    onClickEdit,
    onClickRemove
}: SimpleDataActionBtnsProps) => {
    return (
        <>
            {onClickView ? (
                <button
                    type="button"
                    className="button btn btn-outline-primary mr-3"
                    onClick={onClickView}
                >
                    VIEW
                </button>
            ) : null}

            {onClickAdd ? (
                <button
                    type="button"
                    className={`button btn btn-outline-success mr-3`}
                    onClick={onClickAdd}
                >
                    ADD
                </button>
            ) : null}

            {onClickEdit ? (
                <button
                    type="button"
                    className="button btn btn-outline-warning mr-3"
                    onClick={onClickEdit}
                >
                    EDIT
                </button>
            ) : null}

            {onClickRemove ? (
                <button
                    type="button"
                    className="button btn btn-outline-danger"
                    onClick={onClickRemove}
                >
                    REMOVE
                </button>
            ) : null}
        </>
    );
};

export default SimpleDataActionBtns;
