import { LatLng } from "../googleMap";

interface CreateMapOverlayViewInstanceProps {
    getElement: () => HTMLElement;
    onRemoveVertexCallback?: (updatedVertexGrp: Array<LatLng>) => void;
}

const EditOverlayView = ({
    getElement,
    onRemoveVertexCallback
}: CreateMapOverlayViewInstanceProps) => {
    class MapOverlayView extends window.google.maps.OverlayView {
        menuContainerElement?: HTMLElement = undefined;
        menuItemElement?: HTMLElement = undefined;
        closeMenuHandler?: (e: MouseEvent) => void = undefined;
        onClickDeleteHandler?: (e: MouseEvent) => void = undefined;

        constructor() {
            super();

            const menuContainerElement = document.createElement("div");
            menuContainerElement.className = "context-menu";
            menuContainerElement.style.position = "absolute";
            menuContainerElement.style.zIndex = "100";
            menuContainerElement.style.backgroundColor = "#2b3c46";
            menuContainerElement.style.padding = "6px 8px";
            menuContainerElement.style.color = "#ff0000";
            menuContainerElement.style.border = "1px solid #ff0000";
            menuContainerElement.style.fontSize = "16px";
            menuContainerElement.style.cursor = "alias";
            this.menuContainerElement = menuContainerElement;

            this.menuItemElement = getElement();
            const overlayVertex = this;
            this.onClickDeleteHandler = (e: MouseEvent) => {
                e.stopPropagation();
                e.preventDefault();
                overlayVertex.removeVertex();
            };
            this.menuItemElement.addEventListener("click", this.onClickDeleteHandler);
            menuContainerElement.appendChild(this.menuItemElement);
        }

        onAdd() {
            const overlayVertex = this;
            const map = this.getMap();

            if (!this?.getPanes() || !this.menuContainerElement) return;
            this.getPanes()?.floatPane.appendChild(this.menuContainerElement);
            // mousedown anywhere on the map except on the menu div will close the
            // menu.

            if (!map) return;

            if ("data" in map) {
                const mapContainerDiv = map.getDiv();
                this.closeMenuHandler = (e: MouseEvent) => {
                    if (
                        e.target !== overlayVertex.menuContainerElement &&
                        e.target !== overlayVertex.menuItemElement
                    )
                        overlayVertex.close();
                };
                mapContainerDiv.addEventListener("mousedown", this.closeMenuHandler);
            }
        }

        onRemove() {
            if (this.menuContainerElement?.parentNode) {
                this.menuContainerElement.parentNode.removeChild(this.menuContainerElement);
            }

            // clean up
            this.set("position", null);
            this.set("path", null);
            this.set("vertex", null);
        }

        close() {
            const map = this.getMap();
            if (map && "data" in map && this.closeMenuHandler) {
                const mapContainerDiv = map.getDiv();
                mapContainerDiv.removeEventListener("mousedown", this.closeMenuHandler);
                this.closeMenuHandler = undefined;
            }

            if (this.menuItemElement && this.onClickDeleteHandler) {
                this.menuItemElement.removeEventListener("click", this.onClickDeleteHandler);
                this.onClickDeleteHandler = undefined;
            }

            this.setMap(null);
        }

        open(map: google.maps.Map, path: google.maps.MVCArray<google.maps.LatLng>, vertex: number) {
            this.set("position", path.getAt(vertex));
            this.set("path", path);
            this.set("vertex", vertex);
            this.setMap(map);
            this.draw();
        }

        removeVertex() {
            const path: google.maps.MVCArray<google.maps.LatLng> = this.get("path");
            const vertex: number = this.get("vertex");

            if (!path || vertex === undefined) {
                this.close();
                return;
            }

            path.removeAt(vertex);
            const updatedVertexGrp: Array<LatLng> = [];
            path.forEach((latLng) => updatedVertexGrp.push(latLng.toJSON()));

            this.close();
            onRemoveVertexCallback && onRemoveVertexCallback(updatedVertexGrp);
        }

        // getPosition() {
        //     if (!this.position) return;
        //     return new window.google.maps.LatLng(this.position.lat, this.position.lng);
        // }

        draw() {
            const position = this.get("position");
            const projection = this.getProjection();

            if (!position || !projection) return;

            const point = projection.fromLatLngToDivPixel(position);

            if (!this.menuContainerElement || !point) return;
            this.menuContainerElement.style.top = point.y + "px";
            this.menuContainerElement.style.left = point.x + "px";
        }
    }

    return new MapOverlayView();
};

export default EditOverlayView;
