import React, {useCallback, useContext, useEffect, useState} from "react";
import {DateRangePicker, InputNumber, SelectPicker, TagPicker} from "rsuite";
import moment from 'moment';

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";

import DataTable from "../DataTable";

type ZonerFormProps = {
	zonerID: string|undefined;
	form: any
	detail: any
}

const ZonerForm = ({zonerID, form, detail}: ZonerFormProps) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main} = useContext(MainContext);

	const [zones, setZones] = useState([]);

	const [workers, setWorkers] = useState([]);

	const [workerData, setWorkerData] = useState<any>({});

	const [fields, setFields] = useState<any>([]);

	const fetchFields = useCallback(async () =>
	{
		const response = await fetch(`${main.apiUrl}/fields/getFullList`, {
			method: 'get',
			credentials: 'include',
			headers: {"Content-Type": "application/json"},
		});

		const data = await response.json().catch(error => console.log("REQUEST ERROR:", error));

		console.log(data);

		setFields(data.data);
	}, []);

	useEffect(() =>
	{
		console.log(detail)
		fetchFields();
	}, [])

	return <>
		<div className="row pt-3 pb-4">
			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">ID</label>
					<div className="colors light-2">{detail._id}</div>
				</div>
			</div>

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Device ID</label>
					<div className="colors light-2">{detail.deviceID}</div>
				</div>
			</div>

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Device Name</label>
					<div className="colors light-2">{detail.name}</div>
				</div>
			</div>

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Region</label>
					<div className="colors light-2">
						{detail.region}
					</div>
				</div>
			</div>
		</div>
		<div className="row pt-3 pb-3">
			<div className="col-md-6 col-12">
				<div className="fonts font-20 weight-500 colors light-2">Fields</div>
			</div>
		</div>
		<form onSubmit={form.handleSubmit}>
			<div className="row py-4">
				{
					((fields.length > 0) && (form.values.parameters.length > 0) && fields.map((field: any, index: number) =>
					{
						{
							{
								return (function ()
								{
									switch (field.type)
									{
										case 'USER_INPUT':
										{
											return <div className="col-6 mb-4">
												<div className="weight-500 font-16 mb-3">{field.name}</div>
												<div className="form-input">
													<input type="text" className="form-control" name={`parameters[${index}].value`}
													       placeholder={field.defaultValue}
													       onChange={form.handleChange}
													       value={form.values.parameters[index].value}
													       autoComplete="off"/>
												</div>
											</div>
										}
										case 'NUMBER':
										{
											return <div className="col-6 mb-4">
												<div className="weight-500 font-16 mb-3">{field.name}</div>
												<div className="form-input">
													<InputNumber step={.01}
													             value={form.values.parameters[index].value}
													             onChange={(value) =>
													             {
														             const params = JSON.parse(JSON.stringify(form.values.parameters));
														             params[index].value = value;
														             form.setFieldValue(`parameters`, params)
													             }}/>
												</div>
											</div>
										}
										case 'USER_SELECT':
										{
											return <div className="col-6 mb-4">
												<div className="weight-500 font-16 mb-2">{field.name}</div>
												<div className="form-input">
													<SelectPicker
													className={'select w-100'}
													menuClassName={`selectMenu`}
													data={field.options.map((option: any) => ({label: option.value, value: option.value}))}
													searchable={false}
													cleanable={false}
													appearance="subtle"
													value={form.values.parameters[index].value}
													onChange={(value) =>
													{
														const params = JSON.parse(JSON.stringify(form.values.parameters));
														params[index].value = value;
														form.setFieldValue(`parameters`, params)
													}}
													/>
												</div>
											</div>
										}
									}
								})();
							}
						}
					}))
				}
			</div>
			<div className="text-right">
				<button type="submit" className="btn button btn-theme">Submit</button>
			</div>
		</form>
	</>
}

export default ZonerForm;