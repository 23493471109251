import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ComponentContext } from "../contexts/ComponentContext";
import { MainContext } from "../contexts/MainContext";
import PageHeader from "../components/PageHeader";
import API from "../helpers/API";
import {
    GisMapGeoFenceCreate,
    GisMapGeoFenceUpdate,
    GisMapListRes,
    GisMapListResGeoFenceRecord,
    GisMapListResRecord,
    GisMapListResZoneRecord,
    GisMapZoneCreate,
    GisMapZoneUpdate,
    GisType
} from "../models/apiRes/GisMapListModel";
import SimpleTable, { PAGINATION_OPTIONS } from "../components/SimpleDataTable/SimpleDataTable";
import SimpleDataActionBtns from "../components/SimpleDataTable/SimpleDataActionBtns";
import { PaginationData } from "../components/SimpleDataTable/SimplePagination";
import moment from "moment";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import Modal from "../components/Modal";
import GisMapEditPanel from "../components/GisMap/GisMapEditPanel";
import { CommonRes } from "../models/apiRes/CommonModel";
import {
    COMMON_API_OPTIONS,
    GIS_MAP_LIST_TABLE_SCHEMA,
    GisTypeLabel
} from "../configs/GisMapConfigs";
import { ProjectConfigRes, ProjectConfigResData } from "../models/apiRes/ProjectConfigModel";
import { GisMapListGeoFenceRecord, GisMapListZoneRecord } from "../models/view/GisMapListModel";

const SIDEBAR_CLASSNAME = "with-sidebar";

interface GisMapTableData {
    id: string;
    name: string;
    type: string;
    updatedAt: string;
    updatedBy: string;
}

interface GisMapListProps {
    sidebar: boolean;
}

const GisMapList = ({ sidebar }: GisMapListProps) => {
    const { alert, setAlert } = useContext(ComponentContext);
    const { main, resetToken } = useContext(MainContext);
    const { id } = useParams();
    const [projectConfig, setProjectConfig] = useState<ProjectConfigResData>();

    const [modalLoading, setModalLoading] = useState(false);
    const [resData, setResData] = useState<
        Array<GisMapListResZoneRecord | GisMapListResGeoFenceRecord>
    >([]);
    const [totalRow, setTotalRow] = useState(0);
    const [pagination, setPagination] = useState<PaginationData>({
        totalPages: 0,
        currentPage: 1,
        rowPerPage: PAGINATION_OPTIONS[0].value
    });

    // map model
    const [selectedGis, setSelectedGis] = useState<
        GisMapListZoneRecord | GisMapListGeoFenceRecord | undefined
    >();
    const [mapMode, setMapMode] = useState<"View" | "Edit">("View");

    // delete model
    const [deleteGis, setDeleteGis] = useState<
        GisMapListResZoneRecord | GisMapListResGeoFenceRecord | undefined
    >();

    const errorCallbackHandle = useCallback(
        (error: any) => {
            setAlert({
                ...alert,
                type: error.message.type,
                message: error.message.content
            });
        },
        [alert, setAlert]
    );

    const fetchProjectConfig = useCallback(async () => {
        API.call({
            url: `${main.apiUrl}/projects/config?pid=${id}`,
            options: { ...COMMON_API_OPTIONS, method: "get" },
            successCallback: (res: ProjectConfigRes) => setProjectConfig(res.data),
            errorCallback: errorCallbackHandle,
            resetToken: resetToken
        });
    }, [errorCallbackHandle, id, main.apiUrl, resetToken]);

    const fetchGisList = useCallback(async () => {
        if (!projectConfig?.projectID) return;

        const convetedQuery = queryString.stringify(
            {
                page: pagination.currentPage,
                size: pagination.rowPerPage,
                pid: projectConfig?.projectID
            },
            { skipNull: true }
        );

        API.call({
            url: `${main.apiUrl}/gis/list?${convetedQuery}`,
            options: { ...COMMON_API_OPTIONS, method: "get" },
            successCallback: (res: GisMapListRes) => {
                setResData(res.data.records);
                setTotalRow(res.data.total);
            },
            errorCallback: errorCallbackHandle,
            resetToken: resetToken
        });
    }, [
        errorCallbackHandle,
        main.apiUrl,
        pagination.currentPage,
        pagination.rowPerPage,
        projectConfig?.projectID,
        resetToken
    ]);

    useEffect(() => {
        fetchGisList();
    }, [fetchGisList]);

    useEffect(() => {
        fetchProjectConfig();
    }, [fetchProjectConfig]);

    const tableData: Array<GisMapTableData> = useMemo(() => {
        if (!resData) return [];

        return resData.map((record) => {
            const { _id, name, gisType, updatedAt, updatedBy } = record;
            return {
                id: _id,
                name: name,
                type: gisType,
                updatedAt: updatedAt,
                updatedBy: updatedBy
            };
        });
    }, [resData]);

    const mapCenterConverter = (
        mapRecord: GisMapListResZoneRecord | GisMapListResGeoFenceRecord,
        projectConfigParam?: ProjectConfigResData
    ): GisMapListZoneRecord | GisMapListGeoFenceRecord => {
        if (mapRecord.gisType === "zone") {
            const { zoneGps } = mapRecord;

            return {
                ...mapRecord,
                center: zoneGps
                    ? { lat: zoneGps.lat, lng: zoneGps.long }
                    : {
                          lat:
                              projectConfigParam && projectConfigParam.lat > 0
                                  ? projectConfigParam.lat
                                  : 22.302711,
                          lng:
                              projectConfigParam && projectConfigParam.long > 0
                                  ? projectConfigParam.long
                                  : 114.177216
                      }
                // lat: 22.302711, long: 114.177216 is Hong Kong
            };
        } else {
            // geoFence
            const { geoGps } = mapRecord;
            return {
                ...mapRecord,
                center:
                    geoGps && geoGps[0]
                        ? { lat: geoGps[0].lat, lng: geoGps[0].long }
                        : {
                              lat:
                                  projectConfigParam && projectConfigParam.lat > 0
                                      ? projectConfigParam.lat
                                      : 22.302711,
                              lng:
                                  projectConfigParam && projectConfigParam.long > 0
                                      ? projectConfigParam.long
                                      : 114.177216
                          }
            };
        }
    };

    const onClickViewGis = useCallback(
        (gisId: string) => {
            const found = resData.find((data) => data._id === gisId);
            if (found) {
                setMapMode("View");
                setSelectedGis(mapCenterConverter(found, projectConfig));
            }
        },
        [projectConfig, resData]
    );

    const onClickEditGis = useCallback(
        (gisId: string) => {
            const found = resData.find((data) => data._id === gisId);
            if (found) {
                setMapMode("Edit");
                setSelectedGis(mapCenterConverter(found, projectConfig));
            }
        },
        [projectConfig, resData]
    );

    const onClickDeleteGis = useCallback(
        (gisId: string) => {
            const found = resData.find((data) => data._id === gisId);
            if (found) setDeleteGis(found);
        },
        [resData]
    );

    const onSubmitRemoveGis = useCallback(
        async (id: string) => {
            setModalLoading(true);

            API.call({
                url: `${main.apiUrl}/gis/`,
                options: { ...COMMON_API_OPTIONS, method: "delete", body: JSON.stringify({ id }) },
                successCallback: (res: CommonRes) => {
                    setModalLoading(false);
                    if (res.message.type === "success") {
                        setDeleteGis(undefined);
                        fetchGisList();
                    }
                },
                errorCallback: (error) => {
                    errorCallbackHandle(error);
                    setModalLoading(false);
                },
                resetToken: resetToken
            });
        },
        [errorCallbackHandle, fetchGisList, main.apiUrl, resetToken]
    );

    const customCellRenderer = (schemaKey: string, rowData: GisMapTableData) => {
        if (schemaKey === "action") {
            return (
                <SimpleDataActionBtns
                    onClickView={() => onClickViewGis(rowData.id)}
                    onClickEdit={() => onClickEditGis(rowData.id)}
                    onClickRemove={() => onClickDeleteGis(rowData.id)}
                />
            );
        }

        if (schemaKey === "updatedAt") {
            return <>{moment(rowData.updatedAt).format("YYYY-M-D HH:mm")}</>;
        }

        if (schemaKey === "updatedBy") return <>{rowData.updatedBy}</>;

        return <></>;
    };

    const onClickCreateGis = (gisType: GisType) => {
        if (!projectConfig?.projectID) return;

        const tempBaseData: GisMapListResRecord = {
            _id: "New",
            projectID: projectConfig.projectID,
            name: "",
            color: "000000",
            createdBy: "",
            updatedBy: "",
            createdAt: "",
            updatedAt: ""
        };
        setMapMode("Edit");

        if (gisType === "zone") {
            // create an temp fake gis data
            const tempData: GisMapListResZoneRecord = {
                ...tempBaseData,
                gisType
            };
            setSelectedGis(mapCenterConverter(tempData, projectConfig));
        }

        if (gisType === "geoFence") {
            // create an temp fake gis data
            const tempData: GisMapListResGeoFenceRecord = {
                ...tempBaseData,
                gisType,
                geoType: "work"
            };
            setSelectedGis(mapCenterConverter(tempData, projectConfig));
        }
    };

    const onSubmit = useCallback(async () => {
        let reqBody: GisMapZoneCreate | GisMapGeoFenceCreate | undefined;

        if (!selectedGis) return;

        // default is CREATE api
        if (selectedGis?.gisType === "zone") {
            const { projectID, name, gisType, color, zoneGps } = selectedGis;
            if (!zoneGps) return; // not allow submit empty

            reqBody = {
                projectID,
                name,
                gisType,
                color,
                zoneGps
            };
            setModalLoading(true);
        } else if (selectedGis?.gisType === "geoFence") {
            const { projectID, name, gisType, geoType, color, geoGps } = selectedGis;
            if (!geoGps) return; // not allow submit empty

            reqBody = {
                projectID,
                name,
                gisType,
                geoType,
                color,
                geoGps
            };
        }
        if (!reqBody) return;

        let method = "post";
        if (selectedGis._id !== "New") {
            method = "put";
            (reqBody as GisMapZoneUpdate | GisMapGeoFenceUpdate).id = selectedGis._id;
        }

        API.call({
            url: `${main.apiUrl}/gis/`,
            options: { ...COMMON_API_OPTIONS, method, body: JSON.stringify(reqBody) },
            successCallback: (res: CommonRes) => {
                setModalLoading(false);
                if (res.message.type === "success") {
                    setSelectedGis(undefined);
                    fetchGisList();
                }
            },
            errorCallback: (error) => {
                errorCallbackHandle(error);
                setModalLoading(false);
            },
            resetToken: resetToken
        });
    }, [errorCallbackHandle, fetchGisList, main.apiUrl, resetToken, selectedGis]);

    const onPaginationChange = (paginationData: Partial<PaginationData>) => {
        setPagination({ ...pagination, ...paginationData });
    };

    const clearGeoFenceCallback = () => {
        if (mapMode === "Edit" && selectedGis?.gisType === "geoFence") {
            setSelectedGis({ ...selectedGis, geoGps: [] });
        }
    };

    return (
        <>
            <section className={`content ${sidebar && SIDEBAR_CLASSNAME}`}>
                <PageHeader
                    title="GIS MAP"
                    description="List of GIS"
                    suffixArea={
                        <>
                            <button
                                className="btn button btn-theme mr-2"
                                onClick={() => onClickCreateGis("zone")}
                            >
                                Create Zone
                            </button>
                            <button
                                className="btn button btn-theme"
                                onClick={() => onClickCreateGis("geoFence")}
                            >
                                Create Geo-Fence
                            </button>
                        </>
                    }
                />

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <SimpleTable
                                    schema={GIS_MAP_LIST_TABLE_SCHEMA}
                                    data={tableData}
                                    customCellRenderer={customCellRenderer}
                                    fixedRightColumns={["action"]}
                                    paginationData={pagination}
                                    totalRow={totalRow}
                                    onPaginationChange={onPaginationChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* map modal */}
            <Modal
                show={Boolean(selectedGis !== undefined)}
                close={Boolean(selectedGis === undefined)}
                title={
                    selectedGis
                        ? `${mapMode === "Edit" && selectedGis._id === "New" ? "New" : mapMode} ${
                              GisTypeLabel[selectedGis?.gisType]
                          }`
                        : "null"
                }
                scrollable={false}
                size={"lg"}
                closeButton={true}
                confirmButtons={false}
                closeAction={() => setSelectedGis(undefined)}
                centered={true}
            >
                {selectedGis ? (
                    <GisMapEditPanel
                        mapData={selectedGis}
                        mode={mapMode}
                        errorCallbackHandle={errorCallbackHandle}
                        onChangeMapData={(mapDataParam) => setSelectedGis(mapDataParam)}
                        onSubmit={onSubmit}
                        defaultZoomLv={projectConfig?.zoomLvl}
                        clearGeoFenceCallback={clearGeoFenceCallback}
                    />
                ) : null}
            </Modal>

            {/* delete confirmation modal */}
            <Modal
                show={Boolean(deleteGis !== undefined)}
                close={Boolean(deleteGis === undefined)}
                loading={modalLoading}
                title={"Confirm Remove"}
                size={"md"}
                centered={true}
                confirmButtons={true}
                buttonName={"Remove Confirmation"}
                buttonClass={"btn-danger"}
                closeAction={() => setDeleteGis(undefined)}
                confirmedAction={() => {
                    deleteGis && onSubmitRemoveGis(deleteGis._id);
                }}
            >
                Confirm Remove {deleteGis?.name}?
            </Modal>
        </>
    );
};
export default GisMapList;
