import { Pagination } from "rsuite";

export interface PaginationData {
    totalPages: number;
    currentPage: number;
    rowPerPage: number;
}

interface SimplePaginationProps {
    pagination: PaginationData;
    onChangePagination: (paginationParam: Partial<PaginationData>) => void;
    totalRow: number;
}

const SimplePagination = ({ pagination, onChangePagination, totalRow }: SimplePaginationProps) => {
    return (
        <div className="d-xl-flex d-block justify-content-between align-items-center">
            <div className="fonts font-16">
                Total <span className="fonts weight-700 mx-2">{totalRow}</span> Records
            </div>
            <div className="my-5">
                <Pagination
                    prev
                    next
                    ellipsis
                    boundaryLinks
                    size="lg"
                    total={totalRow}
                    pages={pagination.totalPages}
                    activePage={pagination.currentPage}
                    maxButtons={5}
                    onSelect={(key) =>
                        onChangePagination({
                            currentPage: parseInt(key.toString())
                        })
                    }
                />
            </div>
        </div>
    );
};

export default SimplePagination;
