import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

import {ComponentContext} from "../contexts/ComponentContext";

import Loading from "./Loading";

type QuickPanelProps = {
    title: string;
    subtitle: string;
    children: ReactNode
    show: boolean;
    processing: boolean
    close: boolean;
    closeAction?: () => void
}

export default function QuickPanel({children, title, subtitle, show, processing, close, closeAction}: QuickPanelProps)
{
    const {loading}=useContext(ComponentContext)

    const [quickPanel, setQuickPanel] = useState({
        show: false,
    });

    const dismiss = (e:any) =>
    {
        if (!quickPanel.show)
        {
            if (typeof closeAction === 'function')
                closeAction();

            e.target.classList.remove('active')
        }
    }

    const closeQuickPanel = () =>
    {
        setQuickPanel({...quickPanel, show: false})
    }

    useEffect(() =>
    {
        if (close)
            closeQuickPanel();
    }, [close])

    useEffect(() =>
    {
        if(show)
            document.body.classList.add("hidden");
        else
            document.body.classList.remove("hidden");
    }, [show])

    return (
    <>
        <CSSTransition classNames={{
            appear: 'active animate__animated',
            appearActive: 'active animate__animated animate__fadeInRight animate__faster',
            appearDone: 'active animate__animated animate__fadeInRight animate__faster',
            enter: 'active animate__animated animate__fadeInRight animate__faster',
            enterActive: 'active animate__animated animate__fadeInRight animate__faster',
            enterDone: 'active animate__animated animate__fadeInRight animate__faster',
            exit: 'active animate__animated animate__fadeOutRight animate__faster',
            exitActive: 'active animate__animated animate__fadeOutRight animate__faster',
            exitDone: 'active animate__animated animate__fadeOutRight animate__faster',
        }} in={show} addEndListener={()=>{}}>
            <aside className="sidebar notifications quickpanel">
                <div className="notifications__panel d-block">
                    <div className="sidebar__header">
                        <i className="zwicon-arrow-left sidebar__close"
                           onClick={dismiss}/>
                        <div className='title'>{title}</div>
                        <div className='subtitle'>{subtitle}</div>
                    </div>
                    <div className="notifications__body">
                        {children}
                    </div>
                </div>

                <Loading componentLoader={true} loading={processing}/>
            </aside>
        </CSSTransition>

        {(show) &&
        <div className="quickpanel-backdrop" onClick={dismiss}/>}
    </>
    );
};