interface PageHeaderProps {
    title: string;
    description: string;
    onClickAddBtn?: () => void;
    suffixArea?: JSX.Element;
}

const PageHeader = ({ title, description, onClickAddBtn, suffixArea }: PageHeaderProps) => {
    return (
        <header className="content__title">
            <h1 className="mr-auto">
                {title}
                <small>{description}</small>
            </h1>

            {onClickAddBtn ? (
                <button className="btn button btn-theme" onClick={onClickAddBtn}>
                    Add {title}
                </button>
            ) : null}

            {suffixArea ? suffixArea : null}
        </header>
    );
};

export default PageHeader;
