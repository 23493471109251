const useSso = () => {
  const region = process.env.REACT_APP_AWS_COGNITO_REGION;
  const userPoolName = process.env.REACT_APP_AWS_COGNITO_USER_POOL_NAME;
  const ssoLoginHost = `https://${userPoolName}.auth.${region}.amazoncognito.com`;
  const ssoClientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
  const ssoLoginRedirectUri = `${window.location.origin}/${process.env.REACT_APP_PREFIX}login`;
  const ssoLogoutRedirectUri = `${window.location.origin}/${process.env.REACT_APP_PREFIX}logout`;
  const ssoLoginUrl = `${ssoLoginHost}/login?client_id=${ssoClientId}&response_type=code&scope=email+openid+phone&redirect_uri=${ssoLoginRedirectUri}`;
  const ssoLogoutUrl = `${ssoLoginHost}/logout?client_id=${ssoClientId}&logout_uri=${ssoLogoutRedirectUri}`
  return {
    ssoLoginRedirectUri,
    ssoLogoutRedirectUri,
    ssoLoginUrl,
    ssoLogoutUrl,
  }
}

export default useSso;
