import React, {useCallback, useContext, useEffect, useState} from "react";
import {SelectPicker} from "rsuite";

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";
import API from "../../helpers/API";

type EnvSensorFormProps = {
  projectID: string | undefined;
  form: any
}

const EnvSensorForm = ({projectID, form}: EnvSensorFormProps) => {
  const {alert, setAlert} = useContext(ComponentContext);
  const {main, resetToken} = useContext(MainContext);

  const [zones, setZones] = useState([]);

  const fetchZones = useCallback(async () => {
    const params = new URLSearchParams();

    params.append('projectID', projectID as string);
    params.append('page', '1');
    params.append('size', '1000000');

    API.call({
      url: `${main.apiUrl}/zones?${params.toString()}`,
      options: {
        method: 'get',
        credentials: 'include',
        headers: {"Content-Type": "application/json"},
      },
      successCallback: (res) => {
        setZones(res.data.records.map((zone: any) => ({label: zone.name, value: zone._id})));
      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })
      },
      resetToken: resetToken
    });
  }, []);

  useEffect(() => {
    fetchZones();
  }, [])

  return <>
    <form onSubmit={form.handleSubmit}>
      <div className="row py-4">
        <div className="col-12">
          <div className="form-group">
            <label className="mb-3">Zone</label>
            <SelectPicker
              className={'select w-100'}
              menuClassName={`selectMenu`}
              data={zones}
              searchable={false}
              cleanable={false}
              appearance="subtle"
              value={form.values.zoneID}
              onChange={(value) => {
                form.setFieldValue('zoneID', value);
              }}
            />
            {((form.touched.zoneID && form.errors.zoneID)) && (
              <div
                className="invalid-feedback"
                style={{display: 'block'}}
              >
                {form.errors.zoneID}
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <div className="weight-500 font-16 mb-3">Device ID</div>
            <div className="form-input">
              <input
                id="deviceID"
                name="deviceID"
                type="text"
                className="form-control"
                onChange={form.handleChange}
                value={form.values.deviceID}
                autoComplete="off"/>
            </div>
            {((form.touched.deviceID && form.errors.deviceID)) && (
              <div
                className="invalid-feedback"
                style={{display: 'block'}}
              >
                {form.errors.deviceID}
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="weight-500 font-16 mb-3">Device Name</div>
          <div className="form-input">
            <input
              id="deviceName"
              name="deviceName"
              type="text"
              className="form-control"
              onChange={form.handleChange}
              value={form.values.deviceName}
              autoComplete="off"/>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button type="submit" className="btn button btn-theme">Submit</button>
      </div>
    </form>
  </>
}

export default EnvSensorForm;