import React, {useCallback, useContext, useEffect, useState} from "react";
import {SelectPicker, DateRangePicker} from "rsuite";
import moment from 'moment';

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";
import API from "../../helpers/API";

type SmartWatchFormProps = {
  projectID: string | undefined;
  form: any
  formType: string
}

const SmartWatchForm = ({projectID, form, formType}: SmartWatchFormProps) => {

  return <>
    <form onSubmit={form.handleSubmit}>
      <div className="row py-4">
        <div className="col-12">
          <div className="weight-500 font-16 mb-3">Watch ID</div>
          <div className="form-input">
            <input
              id="watchId"
              name="watchId"
              type="text"
              className="form-control"
              onChange={form.handleChange}
              value={form.values.watchId}
              autoComplete="off"
              disabled={formType=="edit"}/>
          </div>
          {((form.touched.watchId && form.errors.watchId)) && (
            <div
              className="invalid-feedback"
              style={{display: 'block'}}
            >
              {form.errors.watchId}
            </div>
          )}
        </div>
      </div>
      <div className="row py-4">
        <div className="col-12">
          <div className="weight-500 font-16 mb-3">Helmet ID</div>
          <div className="form-input">
            <input
              id="helmetId"
              name="helmetId"
              type="text"
              className="form-control"
              onChange={form.handleChange}
              value={form.values.helmetId}
              autoComplete="off"/>
          </div>
          {((form.touched.helmetId && form.errors.helmetId)) && (
            <div
              className="invalid-feedback"
              style={{display: 'block'}}
            >
              {form.errors.helmetId}
            </div>
          )}
        </div>
      {/* </div>
      <div className="row py-4"> */}
        <div className="col-12">
          <div className="weight-500 font-16 mb-3">Worker ID</div>
          <div className="form-input">
            <input
              id="workerId"
              name="workerId"
              type="text"
              className="form-control"
              onChange={form.handleChange}
              value={form.values.workerId}
              autoComplete="off"/>
          </div>
          {((form.touched.workerId && form.errors.workerId)) && (
            <div
              className="invalid-feedback"
              style={{display: 'block'}}
            >
              {form.errors.workerId}
            </div>
          )}
        </div>
      </div>
      <div className="row py-4">
        <div className="col-12">
            <div className="form-group">
              <label className="mb-3">Valid Through</label>
              <div>
                <DateRangePicker format="yyyy-MM-dd HH:mm"
                                placeholder={'Dates'}
                                placement={`bottomStart`} cleanable={false}
                                defaultValue={[moment(form.values.startDateTime).toDate(), moment(form.values.endDateTime).toDate()]}
                                disabledDate={(date) =>
                                {
                                  return moment(date).isBefore(moment(moment().format('YYYY-MM-DD')))
                                }}
                                ranges={[]}
                                onChange={(dates) =>
                                {
                                  if (dates !== null)
                                  {
                                    form.setFieldValue('startDateTime', moment(dates[0]).toDate())
                                    form.setFieldValue('endDateTime', moment(dates[1]).toDate())
                                  }
                                }}
                />
              </div>
            </div>
          </div>
      </div>
      <div className="text-right">
        <button type="submit" className="btn button btn-theme">Submit</button>
      </div>
    </form>
  </>
}

export default SmartWatchForm;