import { GisMapListGeoFenceRecord, GisMapListZoneRecord } from "../../models/view/GisMapListModel";

interface MapCoverLayerProps {
    mode: "View" | "Edit";
    mapData: GisMapListZoneRecord | GisMapListGeoFenceRecord;
}

const MapCoverLayer = ({ mode, mapData }: MapCoverLayerProps) => {
    // Create zone but no zone selected in dropdown
    const createZoneNoSelection = () =>
        mode === "Edit" && mapData._id === "New" && mapData.gisType === "zone" && !mapData.name;

    const createGeoFenceNoSelection = () =>
        mode === "Edit" &&
        mapData._id === "New" &&
        mapData.gisType === "geoFence" &&
        (!mapData.geoType || !mapData.name || !mapData.color);

    return (
        <>
            {(createZoneNoSelection() || createGeoFenceNoSelection()) && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.7)"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: "20px",
                            color: "#ffffff"
                        }}
                    >
                        {createZoneNoSelection() && "Please select zone first"}
                        {createGeoFenceNoSelection() && "Please select type, name, color code"}
                    </div>
                </div>
            )}
        </>
    );
};

export default MapCoverLayer;
