import React, {ReactNode, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import SimpleBar from "simplebar-react";

import Loading from "./Loading";

type ModalProps = {
	show: boolean;
	close: boolean;
	title?: string;
	scrollable?: boolean;
	size: string;
	content?: string;
	buttonName?: string;
	buttonClass?: string;
	closeButton?: boolean;
	confirmButtons?: boolean;
	centered: boolean;
	loading?: boolean;
	closeAction?: () => void
	confirmedAction?: () => void
	children: ReactNode
}

const Modal = (props: ModalProps) =>
{

	const [modal, setModal] = useState({
		show: false,
	});

	const dismiss = (e: any) =>
	{
		if (!modal.show)
		{
			if (typeof props.closeAction === 'function')
				props.closeAction();

			e.target.classList.remove('active')
		}
	}

	const closeModal = () =>
	{
		console.log("CLOSING MODAL")
		setModal({...modal, show: false})
	}

	useEffect(() =>
	{
		if (props.close)
			closeModal();
	}, [props.close])

	return (
	<>
		<CSSTransition classNames={{
			appear: 'active animate__animated',
			appearActive: 'active animate__animated animate__fadeIn animate__faster',
			appearDone: 'active animate__animated animate__fadeIn animate__faster',
			enter: 'active animate__animated animate__fadeIn animate__faster',
			enterActive: 'active animate__animated animate__fadeIn animate__faster',
			enterDone: 'active animate__animated animate__fadeIn animate__faster',
			exit: 'active animate__animated animate__fadeOut animate__faster',
			exitActive: 'active animate__animated animate__fadeOut animate__faster',
			exitDone: 'active animate__animated animate__fadeOut animate__faster',
		}} in={props.show}
		               onEntered={() =>
		               {
			               setModal({...modal, show: true});
			               console.log("Entering Modal")
		               }} timeout={0}>
			<div className="modal-backdrop" onAnimationEnd={(e) =>
			{
				if (!modal.show)
				{
					const target = e.target as HTMLDivElement;
					target.classList.remove('active');
				}

			}}/>
		</CSSTransition>

		<CSSTransition classNames={{
			appear: 'active animate__animated',
			appearActive: 'active animate__animated animate__fadeInDown animate__faster',
			appearDone: 'active animate__animated animate__fadeInDown animate__faster',
			enter: 'active animate__animated animate__fadeInDown animate__faster',
			enterActive: 'active animate__animated animate__fadeInDown animate__faster',
			enterDone: 'active animate__animated animate__fadeInDown animate__faster',
			exit: 'active animate__animated animate__fadeOutUp animate__faster',
			exitActive: 'active animate__animated animate__fadeOutUp animate__faster',
			exitDone: 'active animate__animated animate__fadeOutUp animate__faster',
		}} in={modal.show} timeout={0}>
			<div className="modal" role="dialog" aria-labelledby="Add Page"
			     aria-hidden="true"
			     onAnimationEnd={dismiss}
			>
				<div className={`modal-dialog modal-${props.size} ${props.centered && 'modal-dialog-centered'}`}
				     onClick={(e) => e.stopPropagation()}>
					<div className="modal-content pb-4">
						{
						(props.title) && (props.title.length > 0 || props.closeButton) &&
							<div className="modal-header pb-0">
								<h5 className="modal-title fonts weight-700 gray-600">
									{props.title}
								</h5>

								{(props.closeButton) &&
									<i className={`zwicon-close closeButton`} onClick={closeModal}/>
								}
							</div>
						}

						{
							(props.scrollable) ? <SimpleBar style={{maxHeight: '80vh'}}>
								<div className="modal-body">
									{props.children}
								</div>
							</SimpleBar> : <div className="modal-body">
								{props.children}
							</div>
						}

						{
						(props.confirmButtons) &&
							<div className="p-4 text-right">
								<button type="button" className={`btn button ${props.buttonClass} mr-3`}
								        onClick={(e) =>
								        {
									        if (typeof props.confirmedAction === 'function')
										        props.confirmedAction();

									        closeModal()
								        }}>{props.buttonName}
								</button>
								<button id="cancel" type="button" className="btn button button btn-theme"
								        onClick={closeModal}>Cancel
								</button>
							</div>
						}
					</div>
					<Loading componentLoader={true} loading={props.loading}/>
				</div>
			</div>

		</CSSTransition>
	</>
	);
};

export default Modal;