import React, {ReactNode} from "react";

type TabPaneProps =
{
	className?: string;
	children: ReactNode
}

type TabHeaderProps =
{
	className?: string;
	filled?: boolean;
	children: ReactNode
}

type TabHeaderItemProps =
{
	index: number;
	className?: string;
	active?: boolean;
	removable?: boolean;
	onRemove?: (index: number) => void;
	onActive?: () => void;
	children: ReactNode
}

type TabContentProps =
{
	className?: string;
	filled?: boolean;
	children: ReactNode
}

type TabContentItemProps =
{
	index: number;
	className?: string;
	active?: boolean;
	children: ReactNode
}

const TabPane = ({className, children}: TabPaneProps) =>
{
	return (
	<div className={`tab-container tabPane ${className}`}>
		{children}
	</div>
	);
};

const TabHeader = ({className, filled, children}: TabHeaderProps) =>
{
	return (
	<ul className={`nav nav-tabs ${(filled && 'nav-fill')} ${className}`} role="tablist">
		{children}
	</ul>
	);
};

const TabHeaderItem = ({index, className, active, removable, onRemove, onActive, children}: TabHeaderItemProps) =>
{
	return (<li className="nav-item" onClick={() =>
	{
		(typeof onActive === 'function') && onActive()
	}}>
		<div className={`nav-link ${active && 'active'} ${className}`}>
			{children}
		</div>
		{
		(removable) &&
			<i className="zwicon-close removeTab" onClick={(e) =>
			{
				e.stopPropagation();
				(typeof onRemove === 'function') && onRemove(index)
			}}/>
		}
	</li>)
}

const TabContent = ({className, filled, children}: TabContentProps) =>
{
	return (
	<div className={`tab-content`}>
		{children}
	</div>
	);
};

const TabContentItem = ({index, className, active, children}: TabContentItemProps) =>
{
	return (
	<div className={`tab-pane fade ${active && 'active show'} ${className}`}>
		{children}
	</div>)
}


export {TabPane, TabHeader, TabContent, TabHeaderItem, TabContentItem};