import iconZoneGreen from "../../assets/images/icon-zone-green.png";

export const ZONE_ICON_SET = {
    zoneGreen: iconZoneGreen
};

export interface DefaultRsuitSelectPickerType {
    label: string;
    value: string;
}

export const GeoType: Array<DefaultRsuitSelectPickerType> = [
    {
        label: "work",
        value: "work"
    },
    {
        label: "danger",
        value: "danger"
    }
];
