import { SimpleTableData } from "../components/SimpleDataTable/SimpleDataTable";

export const GIS_MAP_LIST_TABLE_SCHEMA: Array<SimpleTableData> = [
    {
        key: "name",
        headerLabel: "Name",
        width: 240
    },
    {
        key: "type",
        headerLabel: "Type",
        width: 120
    },
    {
        key: "updatedAt",
        headerLabel: "Updated At",
        customColumn: true,
        width: 150
    },
    {
        key: "updatedBy",
        headerLabel: "Updated By",
        customColumn: true,
        width: 210
    },
    {
        key: "action",
        headerLabel: "Action",
        customColumn: true,
        width: 250
    }
];

export const GisTypeLabel: Record<string, string> = {
    geoFence: "Geo-Fence",
    zone: "Zone"
};

export const COMMON_API_OPTIONS = {
    credentials: "include" as const,
    headers: { "Content-Type": "application/json" }
};
