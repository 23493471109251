import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Grid, Row, Col, InputPicker, Button } from "rsuite";
import {
    DrawInfo,
    GoogleMap,
    GoogleMapExposeFunctions,
    LatLng,
    MarkerOptions,
    VextexActions
} from "../googleMap";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import API from "../../helpers/API";
import { MainContext } from "../../contexts/MainContext";
import { COMMON_API_OPTIONS } from "../../configs/GisMapConfigs";
import { ZoneRes, ZoneResData } from "../../models/apiRes/ZoneListModel";
import { LatLong } from "../../models/view/mapModel";
import { debounce } from "lodash";
import { GisMapListGeoFenceRecord, GisMapListZoneRecord } from "../../models/view/GisMapListModel";
import { DefaultRsuitSelectPickerType, ZONE_ICON_SET } from "./gisConfig";
import TrashIcon from "@rsuite/icons/Trash";
import EditPanelGeofenceInputs from "./EditPanelGeofenceInputs";
import MapCoverLayer from "./MapCoverLayer";

const zoneLabelClassname = "zone-marker-label";

interface GisMapEditPanelProps {
    // "Edit" with mapData id "New" is Create mode
    mode: "View" | "Edit";
    mapData: GisMapListZoneRecord | GisMapListGeoFenceRecord;
    errorCallbackHandle?: (error: any) => void;
    onChangeMapData?: (
        mapDataParam: GisMapListZoneRecord | GisMapListGeoFenceRecord | undefined
    ) => void;
    onSubmit?: () => void;
    defaultZoomLv?: number;
    clearGeoFenceCallback?: () => void;
}

const MapErrorRender = (status: Status) => {
    if (status === Status.FAILURE) return <div>Error</div>;
    return <div>Loading</div>;
};

const GisMapEditPanel = ({
    mode,
    mapData,
    errorCallbackHandle,
    onChangeMapData,
    onSubmit,
    defaultZoomLv = 10,
    clearGeoFenceCallback
}: GisMapEditPanelProps) => {
    const ref = useRef<GoogleMapExposeFunctions>(null);
    const { main, resetToken } = useContext(MainContext);

    const [zoneRes, setZoneRes] = useState<Array<ZoneResData>>();
    const [firstClick, setFirstClick] = useState(false);
    const [colorCode, setColorCode] = useState(mapData.color);

    const convertedMarkers: Record<string, MarkerOptions> | undefined = useMemo(() => {
        const result: Record<string, MarkerOptions> = {};

        if (mapData.gisType === "zone") {
            // View / Create + first clicked on map / Edit
            if (
                mode === "View" ||
                (mode === "Edit" && mapData._id === "New" && firstClick) ||
                (mode === "Edit" && mapData._id !== "New")
            ) {
                const { zoneGps, name } = mapData;
                result[name] = {
                    markerId: name,
                    position: { lat: zoneGps?.lat ?? 0, lng: zoneGps?.long ?? 0 },
                    // TODO: add colour option for zone
                    icon: ZONE_ICON_SET.zoneGreen,
                    label: {
                        text: name,
                        className: zoneLabelClassname
                    },
                    type: "zone"
                };
            }
            return result;
        }

        return result;
    }, [firstClick, mapData, mode]);

    const convertedGeoFences: Record<string, DrawInfo> | undefined = useMemo(() => {
        const result: Record<string, DrawInfo> = {};

        if (mapData.gisType === "geoFence" && mapData._id !== "New") {
            const { _id, color, geoGps } = mapData;
            result[_id] = {
                color: `#${color}`,
                latlng: geoGps
                    ? geoGps.map(({ lat, long }) => ({
                          lat,
                          lng: long
                      }))
                    : [],
                editable: mode === "Edit" ?? false
            };

            return result;
        }

        return result;
    }, [mapData, mode]);

    const convertedDrawPath: Record<string, DrawInfo> | undefined = useMemo(() => {
        const result: Record<string, DrawInfo> = {};

        if (
            mapData.gisType === "geoFence" &&
            mode === "Edit" &&
            mapData.geoGps &&
            mapData._id === "New"
        ) {
            const { _id, color, geoGps } = mapData;
            result[_id] = {
                color: `#${color}`,
                latlng: geoGps.map(({ lat, long }) => ({
                    lat,
                    lng: long
                })),
                editable: false
            };
            return result;
        }

        return result;
    }, [mapData, mode]);

    // Logic for Edit mode
    const zoneOptions: Array<DefaultRsuitSelectPickerType> = useMemo(() => {
        return (
            zoneRes?.map((zone) => ({
                label: zone.zoneName,
                value: zone.zoneName
            })) ?? []
        );
    }, [zoneRes]);

    const fetchZonesData = useCallback(async () => {
        if (!mapData.projectID) return;

        API.call({
            url: `${main.apiUrl}/zones/zoneLabel?projectID=${mapData.projectID}&isMachine=false`,
            options: { ...COMMON_API_OPTIONS, method: "get" },
            successCallback: (res: ZoneRes) => {
                setZoneRes(res.data.records);
            },
            errorCallback: (error) => {
                errorCallbackHandle && errorCallbackHandle(error);
            },
            resetToken: resetToken
        });
    }, [errorCallbackHandle, main.apiUrl, mapData.projectID, resetToken]);

    const onChangeZonePicker = (zoneName: string) => {
        if (mapData.gisType === "zone") {
            if (!onChangeMapData) return;

            // clear marker
            if (zoneName === null) {
                ref.current?.resetMarker();
                return;
            }

            onChangeMapData({
                ...mapData,
                name: zoneRes?.find((zone) => zone.zoneName === zoneName)?.zoneName ?? ""
            });
        }
    };

    const onChangeGeoFenceName = (geoFenceName: string) => {
        onChangeMapData && onChangeMapData({ ...mapData, name: geoFenceName });
    };

    const onChangeGeoFenceType = (geoType: string) => {
        if (mapData.gisType === "geoFence" && onChangeMapData) {
            onChangeMapData({ ...mapData, geoType: geoType as "work" | "danger" });
        }
    };

    const debouncedInputColor = useRef(
        debounce(
            async (
                value: string,
                mapDataParam: GisMapListZoneRecord | GisMapListGeoFenceRecord
            ) => {
                onChangeMapData && onChangeMapData({ ...mapDataParam, color: value });
            },
            1000
        )
    ).current;

    useEffect(() => {
        return () => debouncedInputColor.cancel();
    }, [debouncedInputColor]);

    const onChangeColorPicker = (value: string) => {
        setColorCode(value);
        debouncedInputColor(value, mapData);
    };

    const onChangePolygon = (updatedVertexGrp: Array<LatLng>, action: VextexActions) => {
        if (mapData.gisType === "geoFence" && mapData.geoGps && onChangeMapData) {
            onChangeMapData({
                ...mapData,
                geoGps: updatedVertexGrp.map(({ lat, lng }) => ({ lat, long: lng }))
            });
        }
    };

    const onClick = useCallback(
        (e: google.maps.MapMouseEvent) => {
            if (mode !== "Edit" || !e.latLng) return;

            const { lat, lng } = e.latLng.toJSON();
            const convertedLocation: LatLong = { lat, long: lng };

            if (mapData.gisType === "zone") {
                // add Zone, only allow add one marker at a time

                // so when CREATE marker, new marker only show after first click
                if (mapData._id === "New") setFirstClick(true);

                onChangeMapData &&
                    mapData.name &&
                    onChangeMapData({
                        ...mapData,
                        zoneGps: convertedLocation,

                        _id: mapData._id
                    });
            } else {
                // add geo fence
                if (!mapData.name) {
                    return;
                }
                onChangeMapData &&
                    onChangeMapData({
                        ...mapData,
                        geoGps: mapData.geoGps
                            ? [...mapData.geoGps, ...[convertedLocation]]
                            : [convertedLocation]
                    });
            }
        },
        [mapData, mode, onChangeMapData]
    );

    return (
        <Grid fluid>
            {mapData.gisType === "geoFence" && (
                <EditPanelGeofenceInputs
                    mode={mode}
                    mapData={mapData}
                    onChangeGeoFenceType={onChangeGeoFenceType}
                    onChangeGeoFenceName={onChangeGeoFenceName}
                    colorCode={colorCode}
                    onChangeColorPicker={onChangeColorPicker}
                />
            )}

            {mapData.gisType === "zone" && (
                <Row>
                    <Col sm={12}>
                        <div>Zone:</div>
                        {mode === "Edit" && mapData._id === "New" ? (
                            <InputPicker
                                data={zoneOptions}
                                style={{
                                    width: "100%",
                                    border: mapData.name ? "inherit" : "1px solid red"
                                }}
                                onChange={onChangeZonePicker}
                                onEnter={() => fetchZonesData()}
                            />
                        ) : (
                            <>
                                <div>{mapData.name}</div>
                                <div style={{ marginTop: "10px", fontStyle: "italic" }}>
                                    Markers can be changed on the map
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            )}

            {mode === "Edit" && mapData.gisType === "geoFence" && (
                <Row>
                    <Col sm={24}>
                        <div style={{ textAlign: "right", marginTop: "10px" }}>
                            <TrashIcon
                                style={{ fontSize: "2em", cursor: "pointer" }}
                                onClick={clearGeoFenceCallback}
                            />
                        </div>
                    </Col>
                </Row>
            )}

            <Row>
                <Col sm={24}>
                    <div style={{ position: "relative", height: "300px", marginTop: "10px" }}>
                        <MapCoverLayer mode={mode} mapData={mapData} />

                        <Wrapper
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY ?? ""}
                            render={MapErrorRender}
                        >
                            <GoogleMap
                                ref={ref}
                                center={mapData.center}
                                zoom={defaultZoomLv}
                                markers={convertedMarkers}
                                polygons={convertedGeoFences}
                                onChangePolygon={onChangePolygon}
                                paths={convertedDrawPath}
                                onClick={(e) => onClick(e)}
                            />
                        </Wrapper>
                    </div>
                </Col>
            </Row>

            {mode === "Edit" && (
                <Row>
                    <Col mdOffset={21} md={3}>
                        <Button
                            className="w-100 button btn btn-outline-success mt-3"
                            onClick={onSubmit}
                        >
                            SAVE
                        </Button>
                    </Col>
                </Row>
            )}
        </Grid>
    );
};

export default GisMapEditPanel;
