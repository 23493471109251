type APIProps = {
	url: string;
	options: APIOptions,
	successCallback: (response: any) => void;
	errorCallback: (response: any) => void;
	resetToken: (message?: any) => void;
}

type APIOptions = {
	method: string;
	credentials?: RequestCredentials;
	headers?: any,
	body?: string,
}

class API
{
	static async call({url, options, successCallback, errorCallback, resetToken}: APIProps)
	{
		console.log("========== CALLING API ==========");
		console.log(url);

		let response = await fetch(url, options);

		let error = null

		const res = await response.json().catch(err => error = err);

		console.log(error);

		if (error === null)
		{
			console.log("RESPONSE:", res);

			if (res.statusCode === 200)
			{
				return successCallback(res);
			}
			else
			{
				if (res.statusCode === 401)
				{
					console.log(res);
					return API.refreshToken({url, options, successCallback, errorCallback, resetToken});
				}
				else
				{
					// resetToken(res.message);
					return errorCallback(res);
				}
			}
		}
		else
			return errorCallback({message: {type: 'error', content: JSON.stringify(error)}})
	}

	static async refreshToken({url, options, successCallback, errorCallback, resetToken}: APIProps)
	{
		console.log("============== CALLING REFRESH ================");
		const response = await fetch(`${process.env.REACT_APP_API_URL}/users/refresh`, {
			method: 'post',
			credentials: 'include',
			headers: {'Content-Type': 'application/json'},
		});

		let error = null;

		const res = await response.json().catch(err => error = err);

		if (error === null)
		{
			if (res.statusCode === 200)
			{
				API.call({url, options, successCallback, errorCallback, resetToken});
			}
			else
			{
				resetToken(res.message);
				return errorCallback(res);
			}
		}
		else
		{
			resetToken(error);
			return errorCallback({message: {type: 'error', content: JSON.stringify(error)}})
		}
	}

	static async download({url, options, successCallback, errorCallback, resetToken}: APIProps)
	{
		let response = await fetch(url, options);

		let error = null

		const res = await response.blob().catch(err => error = err);

		if (error === null)
			return successCallback(res);
		else
			return errorCallback({message: {type: 'error', content: JSON.stringify(error)}})
	}
}

export default API