import React, {useCallback, useContext, useEffect, useState} from "react";
import {DateRangePicker, SelectPicker, TagPicker} from "rsuite";
import moment from 'moment';

import {MainContext} from "../../contexts/MainContext";
import {ComponentContext} from "../../contexts/ComponentContext";

import DataTable from "../DataTable";
import {TabContent, TabContentItem, TabHeader, TabHeaderItem, TabPane} from "../TabPane";

type ZonerDetailProps = {
	detail: any;
}

const ZonerDetail = ({detail}: ZonerDetailProps) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main} = useContext(MainContext);

	const [activeTab, setActiveTab] = useState(0);

	const [zones, setZones] = useState([]);

	const [workers, setWorkers] = useState([]);

	const [workerData, setWorkerData] = useState<any>({});

	const [table, setTable] = useState({
		fetched: false,
		data:[]
	})

	const fetchData=()=>
	{
		let data:any={};

		for(const param of detail.parameters)
			data[param.name]=param.value;

		return [data];
	}

	useEffect(() =>
	{
	}, [])

	return <>
		<div className="row pt-3 pb-4">
			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">ID</label>
					<div className="colors light-2">{detail._id}</div>
				</div>
			</div>

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Device ID</label>
					<div className="colors light-2">{detail.deviceID}</div>
				</div>
			</div>

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Device Name</label>
					<div className="colors light-2">{detail.name}</div>
				</div>
			</div>

			<div className="col-md-6 col-12">
				<div className="form-group">
					<label className="mb-3 colors light-5">Region</label>
					<div className="colors light-2">
						{detail.region}
					</div>
				</div>
			</div>

			<div className="col-12">
				<TabPane>
					{
						<TabHeader filled={false}>
							<TabHeaderItem index={0} active={(activeTab === 0)} removable={false} onActive={() =>
							{
								setActiveTab(0)
							}}>
								Fields
							</TabHeaderItem>
						</TabHeader>
					}
					<TabContent>
						<TabContentItem index={0} active={(activeTab === 0)}>
							<div className="col-12">
								<DataTable
								height={300}
								fetched={table.fetched}
								dataSource={`LOCAL`}
								data={fetchData()}
								schema={detail.parameters.map((param:any)=>{
									return {key: param.name, name: param.name, width: 150};
								})}
								pagination={false}
								sortable={false}
								searchable={false}
								viewable={false}
								insertable={false}
								editable={false}
								removable={false}
								onViewButtonClick={(rowData) =>
								{
								}}
								onDataFetch={() =>
								{
								}}
								onFetched={() =>
								{
									setTable({...table, fetched: true})
								}}
								onRemoveButtonClick={(rowData) =>
								{
								}}
								/>
							</div>
						</TabContentItem>

					</TabContent>
				</TabPane>
			</div>
			{/*<div className="col-12">
				<DataTable
				height={300}
				fetched={table.fetched}
				dataSource={`LOCAL`}
				data={detail.workers}
				schema={table.schema}
				pagination={false}
				sortable={false}
				searchable={false}
				viewable={false}
				insertable={false}
				editable={false}
				removable={false}
				onViewButtonClick={(rowData) =>
				{
				}}
				onDataFetch={() =>
				{
				}}
				onFetched={() =>
				{
					setTable({...table, fetched: true})
				}}
				onRemoveButtonClick={(rowData) =>
				{
				}}
				/>
			</div>*/}
		</div>
	</>
}

export default ZonerDetail;