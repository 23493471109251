import React from 'react';
import {NavLink} from "react-router-dom";

const MenuItem = (props: any) =>
{
	return (
	<NavLink to={props.location} className={({isActive}) => ((isActive) ? `colors ${props.activeColor} active` : '')} end={props.end}>
		<i className={`zwicon-${props.icon} ${props.activeColor} colors`}/> {props.label}
	</NavLink>
	);
};

export default MenuItem;