import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { ComponentContext } from "./ComponentContext";
import API from "../helpers/API";

type MainContextState = {
    title: string;
    isAdmin: boolean;
    username: string;
    apiUrl: string | undefined;
    appUrl: string | undefined;
    expired: boolean;
    validated: boolean;
};

const defaultValues = {
    main: {
        title: "Admin Panel",
        isAdmin: false,
        username: "",
        apiUrl: process.env.REACT_APP_API_URL,
        appUrl: process.env.REACT_APP_APP_URL,
        expired: true,
        validated: false
    },
    resetToken: (message: any) => {},
    validateToken: () => {},
    logout: () => {},
    setMainContext: (states: any) => {}
};

export const MainContext = createContext(defaultValues);

const MainContextProvider = (props: any) => {
    const { alert, setAlert, loading, setLoading, resetComponentContext } =
        useContext(ComponentContext);

    const location = useLocation();

    const [main, setMainContext] = useState<MainContextState>(defaultValues.main);

    const setToken = useCallback(() => {
        setMainContext({
            ...main,
            expired: false,
            validated: true
        });
    }, []);

    const resetToken = async () => {
        setMainContext({
            ...main,
            validated: true,
            expired: true
        });
    };

    const logout = async () => {
        API.call({
            url: `${main.apiUrl}/users/logout`,
            options: {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            },
            successCallback: (res) => {
                setLoading({
                    ...loading,
                    show: false
                });

                setMainContext({
                    ...main,
                    validated: true,
                    expired: true
                });
            },
            errorCallback: (error) => {
                setLoading({
                    ...loading,
                    show: false
                });

                setAlert({
                    ...alert,
                    type: error.message.type,
                    message: error.message.content
                });

                setMainContext({
                    ...main,
                    validated: true,
                    expired: true
                });
            },
            resetToken: resetToken
        });
    };

    const validateToken = useCallback(() => {
        console.log("===== HAVE TOKEN, READY TO VALIDATE TOKEN =====");

        resetComponentContext();

        setMainContext({
            ...main,
            validated: false
        });

        setLoading({
            ...loading,
            show: true
        });

        API.call({
            url: `${main.apiUrl}/users/validate`,
            options: {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            },
            successCallback: (res) => {
                setLoading({
                    ...loading,
                    show: false
                });

                setMainContext({
                    ...main,
                    isAdmin: res.data.isAdmin,
                    username: res.data.username,
                    expired: false,
                    validated: true
                });
            },
            errorCallback: (error) => {
                console.log("ERROR WHEN VALIDATE");

                setLoading({
                    ...loading,
                    show: false
                });

                setAlert({
                    ...alert,
                    type: error.message.type,
                    message: error.message.content
                });
            },
            resetToken: resetToken
        });
    }, []);

    useEffect(() => {
        console.log("=========== INITIAL PAGE LOAD ==========");
        validateToken();
    }, []);

    useEffect(() => {
        console.log("=========== MAIN CONTEXT ==========");
        console.log("MAIN:", main);
    }, [main]);

    return (
        <MainContext.Provider value={{ main, resetToken, validateToken, setMainContext, logout }}>
            {props.children}
        </MainContext.Provider>
    );
};

export default MainContextProvider;
