import { Col, Input, InputPicker, Row } from "rsuite";
import { GisMapListGeoFenceRecord } from "../../models/view/GisMapListModel";
import { GeoType } from "./gisConfig";

interface EditPanelGeofenceInputsProps {
    mode: "View" | "Edit";
    mapData: GisMapListGeoFenceRecord;
    onChangeGeoFenceType: (value: string) => void;
    onChangeGeoFenceName: (value: string) => void;
    colorCode: string;
    onChangeColorPicker: (value: string) => void;
}

const EditPanelGeofenceInputs = ({
    mode,
    mapData,
    onChangeGeoFenceType,
    onChangeGeoFenceName,
    colorCode,
    onChangeColorPicker
}: EditPanelGeofenceInputsProps) => {
    return (
        <Row>
            <Col sm={8}>
                <div>Type:</div>
                {mode === "View" ? (
                    <div>{mapData.geoType}</div>
                ) : (
                    <InputPicker
                        data={GeoType}
                        onChange={onChangeGeoFenceType}
                        value={mapData.geoType}
                    />
                )}
            </Col>
            <Col sm={8}>
                <div>name:</div>
                {mode === "View" ? (
                    <div>{mapData.name}</div>
                ) : (
                    <Input
                        onChange={onChangeGeoFenceName}
                        value={mapData.name}
                        style={{
                            border: mapData.name ? "inherit" : "1px solid red"
                        }}
                    />
                )}
            </Col>
            <Col sm={8}>
                <div>Color Code:</div>
                {mode === "View" ? (
                    <div>{colorCode}</div>
                ) : (
                    <Input
                        value={colorCode}
                        onChange={onChangeColorPicker}
                        style={{
                            border: colorCode ? "inherit" : "1px solid red"
                        }}
                    />
                )}
            </Col>
        </Row>
    );
};

export default EditPanelGeofenceInputs;
