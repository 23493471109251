import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Modal from '../components/Modal';
import DataTable from "../components/DataTable";
import API from "../helpers/API";

const ProjectPage = (props: any) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);

	const navigate = useNavigate();

	const [table, setTable] = useState({
		fetched: false
	})

	const [projectTable, setProjectTable] = useState({
		fetched: false
	})

	const [filters, setFilters] = useState({
		page: 1,
		size: 20,
		total: 0,
		keyword: '',
		sorting: {column: null, type: null}
	});

	const [modal, setModal] = useState({
		show: false,
		close: false,
		loading: true,
		data: {}
	});

	const [removeModal, setRemoveModal] = useState({
		show: false,
		close: false,
		loading: true,
		data: {id: ''}
	});

	const [projects, setProjects] = useState({
		schema: [],
		data: [],
	})

	const createProject = useCallback(async (data: any) =>
	{
		setModal({
			...modal,
			loading: true
		})

		API.call({
			url: `${main.apiUrl}/projects/`,
			options: {
				method: 'post',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(data)
			},
			successCallback: (res) =>
			{
				setModal({
					...modal,
					loading: false,
					close: true,
				})

				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				console.log("ERROR WHEN VALIDATE");

				setModal({
					...modal,
					loading: false,
				})

				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}, [modal]);


	const removeProject = async (id: string) =>
	{
		API.call({
			url: `${main.apiUrl}/projects/`,
			options: {
				method: 'delete',
				credentials: 'include',
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify({id: id})
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: res.message.type,
					message: res.message.content
				})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})
			},
			resetToken: resetToken
		});
	}

	const updateModal = useCallback((properties: any) =>
	{
		console.log("UPDATING MODAL");

		setModal({
			...modal,
			...properties
		})

	}, [modal])

	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Projects<small>List Of Site Projects With Control Zones</small></h1>
				<button className="btn button btn-theme" onClick={e =>
				{
					setModal({...modal, show: true})
					setProjectTable({...projectTable, fetched: false})
				}}>
					Add Project
				</button>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<DataTable
									fetched={table.fetched}
									dataSource={`REMOTE`}
									pagination={true}
									sortable={true}
									searchable={true}
									viewable={true}
									insertable={false}
									editable={false}
									removable={true}
									dataUrl={`${main.apiUrl}/projects`}
									onViewButtonClick={(rowData) =>
									{
										navigate(`/${rowData._id}/zones`)
									}}
									onDataFetch={() =>
									{
									}}
									onFetched={() =>
									{
										setTable({...table, fetched: true})
									}}
									onRemoveButtonClick={(rowData) =>
									{
										setRemoveModal({...removeModal, show: true, data: {id: rowData._id}})
									}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<Modal
		show={modal.show}
		close={modal.close}
		loading={false}
		title={"Attend Project List"}
		scrollable={false}
		size={"lg"}
		closeButton={true}
		confirmButtons={false}
		closeAction={() =>
		{
			updateModal({show: false})
		}}
		centered={true}
		>
			{(modal.show) &&
				<DataTable
					fetched={projectTable.fetched}
					dataSource={`REMOTE`}
					pagination={true}
					sortable={true}
					searchable={true}
					viewable={false}
					insertable={true}
					editable={false}
					removable={false}
					dataUrl={`${main.apiUrl}/projects/fetch`}
					onDataFetch={() =>
					{
					}}
					onFetched={() =>
					{
						updateModal({loading: false})
						setProjectTable({...projectTable, fetched: true})
					}}
					onInsertButtonClick={(rowData) =>
					{
						console.log(rowData);
						createProject(rowData);
					}}
				/>
			}
		</Modal>

		<Modal
		show={removeModal.show}
		close={removeModal.close}
		title={'Confirm Remove Project'}
		scrollable={true}
		size={"md"}
		centered={false}
		confirmButtons={true}
		buttonName={'Confirm Remove'}
		buttonClass={'btn-danger'}
		closeAction={() =>
		{
			setRemoveModal({...removeModal, show: false})
		}}
		confirmedAction={() =>
		{
			removeProject(removeModal.data.id);
		}}
		>
			Confirm RemoveProject? All Related<br/><br/>
			Zones <br/> 
			Approval List<br/> 
			Zoners<br/>
			Worker List<br/>
			SMS Phone List<br/>
			AI Cameras<br/>
			Machines<br/>
			Gas Sensors<br/>
			Env Sensors<br/>
			ELocks<br/>
			Smartwatches<br/><br/>
			Will Also Be Deleted, THIS CANNOT BE UNDONE!!!
		</Modal>
	</>
	);
}

export default ProjectPage;