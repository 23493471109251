import React, {useCallback, useContext, useState} from 'react';
import {useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import moment from 'moment';

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Modal from '../components/Modal';
import DataTable from "../components/DataTable";

import API from "../helpers/API";
import { SmartWatchFormFields } from '../types/SmartWatchForm';
import SmartWatchForm from '../components/SmartWatch/Form';

const defaultFormValues: SmartWatchFormFields = {
  id: '',
  projectID: '',
  watchId: '',
  helmetId: '',
  workerId: '',
  startDateTime: moment().toDate(),
  endDateTime: moment().toDate(),
}

const SmartWatchPage = (props: any) => {
  const {alert, setAlert} = useContext(ComponentContext);
  const {main, resetToken} = useContext(MainContext);
  const {id} = useParams();
  const [table, setTable] = useState({
    fetched: false
  })

  const [modal, setModal] = useState({
    show: false,
    close: false,
    loading: false,
    title: 'Add SmartWatch Form',
    data: {},
    formType: 'add'
  });

  const [removeModal, setRemoveModal] = useState({
    show: false,
    close: false,
    loading: true,
    data: {id: ''}
  });

  const form = useFormik({
    initialValues: defaultFormValues,
    validationSchema: Yup.object({
    //   zoneID: Yup.string().required("Please select Zone"),
      watchId: Yup.string().required("Please input Watch ID"),
      helmetId: Yup.string(),
      workerId: Yup.string().when("helmetId", {
        is: (helmetId: string) => !helmetId || helmetId.length === 0,
        then: Yup.string().required("Either Helmet ID or Worker ID is required"),
        otherwise: Yup.string()
      }),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      if (values.id === '') {
        createSmartWatch(values);
      }
      else{
        updateSmartWatch(values);
      }
    },
  })

  const closeModal = useCallback(() => {
    form.resetForm();
    setModal({...modal, show: false})
  }, [modal]);

  const createSmartWatch = useCallback((values: any) => {
    setModal({...modal, loading: true})

    let formData = JSON.parse(JSON.stringify(values));

    formData.projectID = id;

    API.call({
      url: `${main.apiUrl}/smart-watches`,
      options: {
        method: 'post',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData)
      },
      successCallback: (res) => {
        setAlert({
          ...alert,
          show: true,
          type: 'success',
          message: res.message.content
        })

        setModal({...modal, loading: false, close: true})

        setTable({...table, fetched: false})
      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })

        setModal({...modal, loading: false})
      },
      resetToken: resetToken
    });
  }, [modal, table]);

  const fetchSmartWatch = useCallback(async (smartWatchMongoId: any) =>
	{
		const response = await fetch(`${main.apiUrl}/smart-watches/fetch`, {
			method: 'post',
			credentials: 'include',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({id: smartWatchMongoId})
		});

		let error = null

		const res = await response.json().catch(err => error = err);

		console.log(res.data);

		console.log(error);

		if (error === null)
		{
			if (res.statusCode === 200)
			{
				return res.data;
			}
			else
			{
				console.log(res);

				setAlert({
					...alert,
					show: true,
					type: 'danger',
					message: res.message.content
				})

				setModal({...modal, loading: false})
			}
		}
		else
		{
			setAlert({
				...alert,
				show: true,
				type: 'danger',
				message: error
			})

			setModal({...modal, loading: false})
		}
	}, [modal, table]);

  const updateSmartWatch = useCallback(async (values: any) =>
	{
		setModal({...modal, loading: true})

		let formData = JSON.parse(JSON.stringify(values));

		formData.projectID = id;

		API.call({
			url: `${main.apiUrl}/smart-watches`,
			options: {
				method: 'put',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(formData)
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: 'success',
					message: res.message.content
				})

				setModal({...modal, loading: false, close: true})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: 'danger',
					message: error.message.content
				})

				setModal({...modal, loading: false})
			},
			resetToken: resetToken
		});

	}, [modal, table]);

  const setSmartWatchForm = useCallback(async (rowData: any) =>
	{
		const smartWatch = await fetchSmartWatch(rowData.id);

		form.setValues({
			id: smartWatch.id,
			projectID: id as string,
			watchId: smartWatch.watchId,
			helmetId: smartWatch.helmetId,
      workerId: smartWatch.workerId,
			startDateTime: moment(smartWatch.startDateTime).toDate(),
			endDateTime: moment(smartWatch.endDateTime).toDate()
		})

		setModal({...modal, title: 'Edit Smartwatch Form', show: true, formType: 'edit'})
	}, [modal])

  const removeSmartWatch = (id: string) => {
    API.call({
      url: `${main.apiUrl}/smart-watches`,
      options: {
        method: 'delete',
        credentials: 'include',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({id: id})
      },
      successCallback: (res) => {
        setAlert({
          ...alert,
          show: true,
          type: res.message.type,
          message: res.message.content
        })

        setTable({...table, fetched: false})
      },
      errorCallback: (error) => {
        setAlert({
          ...alert,
          show: true,
          type: error.message.type,
          message: error.message.content
        })
      },
      resetToken: resetToken
    });
  }

  return (
    <>
      <section className={`content ${props.sidebar}`}>
        <header className="content__title">
          <h1 className="mr-auto">Smart Watches<small>List of Smart Watches</small></h1>
          <button className="btn button btn-theme" onClick={e => {
            setModal({...modal, title: 'Add SmartWatch', show: true, close: false, formType: 'add'})
          }}>
            Add SmartWatch
          </button>
        </header>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <DataTable
                      fetched={table.fetched}
                      dataSource={`REMOTE`}
                      pagination={true}
                      sortable={true}
                      searchable={true}
                      viewable={false}
                      insertable={false}
                      editable={true}
                      removable={true}
                      dataUrl={`${main.apiUrl}/smart-watches?projectID=${id}`}
                      onDataFetch={() => {
                      }}
                      onFetched={() => {
                        setTable({...table, fetched: true})
                      }}
                      onRemoveButtonClick={(rowData) => {
                        setRemoveModal({...removeModal, show: true, data: {id: rowData.id}})
                      }}
                      onEditButtonClick={(rowData) => {
                        setSmartWatchForm(rowData)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={modal.show}
        close={modal.close}
        loading={modal.loading}
        title={modal.title}
        scrollable={true}
        size={"lg"}
        closeButton={true}
        confirmButtons={false}
        closeAction={closeModal}
        centered={true}
      >
        {(modal.show) &&
          <>
            <SmartWatchForm projectID={id} form={form} formType={modal.formType}/>
          </>
        }
      </Modal>

      <Modal
        show={removeModal.show}
        close={removeModal.close}
        title={'Confirm Remove Smart Watch'}
        scrollable={true}
        size={"md"}
        centered={false}
        confirmButtons={true}
        buttonName={'Confirm Remove'}
        buttonClass={'btn-danger'}
        closeAction={() => {
          setRemoveModal({...removeModal, show: false})
        }}
        confirmedAction={() => {
          removeSmartWatch(removeModal.data.id);
        }}
      >
        Confirm Remove Smart Watch?<br/>THIS CANNOT BE UNDONE!!!
      </Modal>
    </>
  );
}

export default SmartWatchPage;