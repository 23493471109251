import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Modal from '../components/Modal';
import DataTable from "../components/DataTable";
import ZonerDetail from "../components/Zoner/Detail";
import {useFormik} from "formik";
import ZonerForm from "../components/Zoner/Form";
import API from "../helpers/API";

const ZonerPage = (props: any) =>
{
	const {alert, setAlert} = useContext(ComponentContext);
	const {main, resetToken} = useContext(MainContext);

	const {projectID, zoneID} = useParams();

	const navigate = useNavigate();

	const [table, setTable] = useState({
		fetched: false
	})

	const [zonerDetail, setZonerDetail] = useState<any>({});

	const [modal, setModal] = useState({
		show: false,
		close: false,
		loading: false,
		title: 'Create Approval Form',
		data: {}
	});

	const [editModal, setEditModal] = useState({
		show: false,
		close: false,
		loading: false,
		data: {}
	});

	const form = useFormik({
		initialValues: {
			id:'',
			parameters: []
		},

		enableReinitialize: true,

		validateOnChange: false,

		onSubmit: (values) =>
		{
			updateZoner(values);
		},
	})

	const updateZoner = useCallback(async (values: any) =>
	{
		setEditModal({...editModal, loading: true})

		let formData = JSON.parse(JSON.stringify(values));

		API.call({
			url: `${main.apiUrl}/zoners`,
			options: {
				method: 'put',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(formData)
			},
			successCallback: (res) =>
			{
				setAlert({
					...alert,
					show: true,
					type: 'success',
					message: res.message.content
				})

				setEditModal({...editModal, loading: false})

				setTable({...table, fetched: false})
			},
			errorCallback: (error) =>
			{
				setAlert({
					...alert,
					show: true,
					type: error.message.type,
					message: error.message.content
				})

				setEditModal({...editModal, loading: false})
			},
			resetToken: resetToken
		});

		const response = await fetch(`${main.apiUrl}/zoners`, {
			method: 'put',
			credentials: 'include',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(formData)
		});
	}, [editModal])

	const closeModal = useCallback(() =>
	{
		setModal({...modal, show: false})
	}, [modal]);

	const closeEditModal = useCallback(() =>
	{
		setEditModal({...editModal, show: false})
	}, [editModal]);

	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Zoners<small>List Of Zoners In The Zone</small></h1>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<DataTable
									fetched={table.fetched}
									dataSource={`REMOTE`}
									pagination={true}
									sortable={true}
									searchable={true}
									viewable={true}
									insertable={false}
									editable={true}
									removable={false}
									dataUrl={`${main.apiUrl}/zoners?zoneID=${zoneID}`}
									onViewButtonClick={(rowData) =>
									{
										setZonerDetail(rowData);
										setModal({...modal, show: true})
									}}
									onEditButtonClick={(rowData) =>
									{
										setZonerDetail(rowData);

										form.setValues({
											id:rowData._id,
											parameters: rowData.parameters
										});

										setEditModal({...modal, show: true})
									}}
									onDataFetch={() =>
									{
									}}
									onFetched={() =>
									{
										setTable({...table, fetched: true})
									}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<Modal
		show={modal.show}
		close={modal.close}
		loading={modal.loading}
		title={"View Zoner Detail"}
		scrollable={true}
		size={"lg"}
		closeButton={true}
		confirmButtons={false}
		closeAction={closeModal}
		centered={true}
		>
			{(modal.show) &&
				<>
					<ZonerDetail detail={zonerDetail}/>
				</>
			}
		</Modal>

		<Modal
		show={editModal.show}
		close={editModal.close}
		loading={editModal.loading}
		title={"Edit Zoner Detail"}
		scrollable={true}
		size={"lg"}
		closeButton={true}
		confirmButtons={false}
		closeAction={closeEditModal}
		centered={true}
		>
			{(editModal.show) &&
				<>
					<ZonerForm zonerID={zoneID} form={form} detail={zonerDetail}/>
				</>
			}
		</Modal>
	</>
	);
}

export default ZonerPage;